import { FunctionComponent } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import VerifyLoginOtpPage from "./pages/VerifyLoginOtpPage";
import Dashboard from "./pages/Dashboard";
import Departures from "./pages/Departures";
import Landing from "./pages/Landing";
import Logout from "./pages/Logout";
import CreateAccount from "./pages/CreateAccount";
import AuthContainer from "./components/AuthContainer";
import { CookiePolicy, PrivacyPolicy } from "./pages/Policies";

const AuthorizedRoutes: FunctionComponent = () => (
	<AuthContainer>
		<Routes>
			<Route path="dashboard" element={<Dashboard />} />
			<Route path="departures/:from?/:to?" element={<Departures />} />
			<Route path="logout" element={<Logout />} />
		</Routes>
	</AuthContainer>
);

function App() {
	return (
		<Router>
			<Routes>
				<Route path="" element={<Landing />} />
				<Route path="login" element={<LoginPage />} />
				<Route
					path="verify-login-otp"
					element={<VerifyLoginOtpPage />}
				/>
				<Route path="signup/:type" element={<CreateAccount />} />
				<Route path="privacy-policy" element={<PrivacyPolicy />} />
				<Route path="cookie-policy" element={<CookiePolicy />} />
				<Route path="*" element={<AuthorizedRoutes />} />
			</Routes>
		</Router>
	);
}

export default App;
