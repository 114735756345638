import { createSlice } from "@reduxjs/toolkit";
import { stopTrackingTrain, trackTrain } from "./effects";
import { ApiError } from "../../types";
import { initialState } from "./state";

const trackingSlice = createSlice({
	name: "tracking",
	initialState: initialState,
	reducers: {
		clearError: (state) => {
			state.error = null;
		},
	},
	extraReducers(builder) {
		builder.addCase(trackTrain.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(trackTrain.fulfilled, (state) => {
			state.loading = false;
			state.error = null;
		});
		builder.addCase(trackTrain.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload as ApiError;
			console.log(action);
		});
		builder.addCase(stopTrackingTrain.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(stopTrackingTrain.fulfilled, (state) => {
			state.loading = false;
			state.error = null;
		});
		builder.addCase(stopTrackingTrain.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload as ApiError;
		});
	},
});

export default trackingSlice.reducer;
export const { clearError } = trackingSlice.actions;
