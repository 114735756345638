import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { ApiError, Service } from "../../types";
import CallingPoint from "./CallingPoint";
import TrackButton from "./TrackButton";

import "./ServiceModalView.scss";

export interface ServiceModalViewProps {
	loading: boolean;
	error: ApiError | null;
	service: Service | null;
	origin: string;
	destination: string;
	onClose: () => void;
	onCloseError: () => void;
}

const ServiceModalView: FunctionComponent<ServiceModalViewProps> = ({
	loading,
	error,
	service,
	origin,
	destination,
	onClose,
	onCloseError,
}) => {
	const renderError = () => {
		if (!error) {
			return null;
		}

		if (error.error === "TrialExpired") {
			return (
				<div
					className="alert alert-dismissible alert-danger"
					role="alert"
				>
					<h5 className="alert-heading">Your trial has expired!</h5>
					<p className="mb-0">
						Choose a <Link to="/account/plan">plan</Link> to start
						tracking more trains.
					</p>

					<button
						type="button"
						className="btn-close"
						aria-label="Close"
						onClick={onCloseError}
					></button>
				</div>
			);
		}

		if (error.error === "QuotaExceeded") {
			return (
				<div
					className="alert alert-dismissible alert-danger"
					role="alert"
				>
					<h5 className="alert-heading">
						You've reached your limit!
					</h5>
					<p className="mb-0">
						Upgrade your <Link to="/account/plan">plan</Link> to
						start tracking more trains.
					</p>

					<button
						type="button"
						className="btn-close"
						aria-label="Close"
						onClick={onCloseError}
					></button>
				</div>
			);
		}

		return (
			<div className="alert alert-dismissible alert-danger" role="alert">
				<h5 className="alert-heading">Oops, something went wrong!</h5>
				<p className="mb-0">{error.message}</p>

				<button
					type="button"
					className="btn-close"
					aria-label="Close"
					onClick={onCloseError}
				></button>
			</div>
		);
	};

	if (loading && !service) {
		return (
			<>
				<div className="modal d-block">
					<div className="modal-dialog">
						<div className="modal-content">
							<div className="modal-body">
								<div
									className="progress"
									style={{ height: "20px" }}
								>
									<div className="progress-bar progress-bar-striped progress-bar-animated w-100" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="modal-backdrop show" onClick={onClose} />
			</>
		);
	}

	if (!service) {
		return (
			<>
				<div className="modal d-block">
					<div className="modal-dialog">
						<div className="modal-content">
							<div className="modal-header modal-header-dark">
								<h5 className="modal-title">Oops,</h5>
								<button
									type="button"
									className="btn-close"
									onClick={onClose}
									aria-label="Close"
								></button>
							</div>
							<div className="modal-body">
								<p>We we're unable to find this service!</p>
								<p>
									<small>
										This service is most likely an old
										service and is no longer running.
										Checkout the{" "}
										<Link
											to={`/departures/${origin}/${destination}`}
										>
											departures
										</Link>{" "}
										pages for mroe.
									</small>
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="modal-backdrop show" onClick={onClose} />
			</>
		);
	}

	return (
		<>
			<div className="modal d-block">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header modal-header-dark">
							<h5 className="modal-title">
								{service.operatorName}
							</h5>
							<button
								type="button"
								className="btn-close"
								onClick={onClose}
								aria-label="Close"
							></button>
						</div>
						<div className="modal-body">
							{renderError()}

							<ul className="tt-service-locations">
								{service.callingPoints.map((cp) => (
									<CallingPoint
										key={cp.crs}
										callingPoint={cp}
									/>
								))}
							</ul>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-secondary"
								onClick={onClose}
							>
								Close
							</button>
							<TrackButton
								serviceId={service.id}
								origin={origin}
								destination={destination}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="modal-backdrop show" onClick={onClose} />
		</>
	);
};

export default ServiceModalView;
