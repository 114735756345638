import { FunctionComponent, useEffect } from "react";
import { useAuth } from "../../hooks";
import { useNavigate } from "react-router";

const Logout: FunctionComponent = () => {
	const { ok: isLoggedIn, logout } = useAuth();
	const navigate = useNavigate();

	useEffect(() => {
		if (isLoggedIn) {
			logout();
			navigate("/");
		}
	}, [isLoggedIn, logout, navigate]);

	return null;
};

export default Logout;
