import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { ApiError, StationLocation } from "../types";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { BASE_URL } from "../constants/api";
import { useAuth } from "../hooks";
import { Option } from "react-bootstrap-typeahead/types/types";

export interface SelectStationLocationProps {
	crs: string | null;
	onChange: (crs: string | null) => void;
}

interface StationLocationOption {
	id: string; // crs
	label: string;
	selected: boolean;
}

const SelectStationLocation: FunctionComponent<SelectStationLocationProps> = ({
	crs,
	onChange,
}) => {
	const [loading, setLoading] = useState(false);
	const [locations, setLocations] = useState<StationLocationOption[]>([]);
	const [searchedCrs, setSearchedCrs] = useState<string>();
	const { ok: isLoggedIn, accessToken } = useAuth();

	const handleSearch = useCallback(
		(term: string) => {
			if (loading || !isLoggedIn || searchedCrs === term) {
				return;
			}

			setLoading(true);

			term = term.replace(/\([A-Z]+\)/, "").trim();

			const url =
				BASE_URL +
				"/station-locations?term=" +
				encodeURIComponent(term);

			fetch(url, {
				method: "GET",
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
				.then(async (res) => {
					console.log(res);
					switch (res.status) {
						case 200:
							const locations =
								(await res.json()) as StationLocation[];
							setLoading(false);
							setLocations(
								locations.map(
									(l) =>
										({
											id: l.crs,
											label: `${l.name} (${l.crs})`,
											selected: l.crs === term,
										} as StationLocationOption)
								)
							);
							setSearchedCrs(term);
							return;
						case 400:
						case 500:
							const error = (await res.json()) as ApiError;
							throw new Error(error.message);
						default:
							throw new Error(
								"API returned status code: " + res.status
							);
					}
				})
				.catch((e) => {
					console.group(
						"An error occured while fetching station locations"
					);
					console.log("URL", url);
					console.error(e);
					console.groupEnd();
					setLoading(false);
				});
		},
		[accessToken, isLoggedIn, loading, searchedCrs]
	);

	useEffect(() => {
		if (crs) {
			handleSearch(crs);
		}
	}, [crs, handleSearch]);

	const handleChange = (options: any[]) => {
		const selectedOptions = options as StationLocationOption[];
		if (selectedOptions.length) {
			onChange(selectedOptions[0].id);
		} else {
			onChange(null);
		}
	};

	// Bypass client-side filtering by returning `true`. Results are already
	// filtered by the search endpoint, so no need to do it again.
	const filterBy = () => true;

	const selected: Option[] = [];
	const location = locations.find((x) => x.id === crs);
	if (location) {
		selected.push(location);
	}

	return (
		<AsyncTypeahead
			id="station-location"
			filterBy={filterBy}
			isLoading={loading}
			onSearch={handleSearch}
			placeholder="Search for a station..."
			options={locations}
			selected={selected}
			onChange={handleChange}
		/>
	);
};

export default SelectStationLocation;
