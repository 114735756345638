import { FunctionComponent, useEffect } from "react";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import SubscriptionFormView from "./SubscriptionFormView";
import { Stripe, StripeElements } from "@stripe/stripe-js";

export interface SubscriptionFormProps {
	onProviderLoad: (
		stripe: Stripe | null,
		elements: StripeElements | null
	) => void;
}

const SubscriptionForm: FunctionComponent<SubscriptionFormProps> = ({
	onProviderLoad,
}) => {
	const stripe = useStripe();
	const elements = useElements();

	useEffect(() => {
		onProviderLoad(stripe, elements);
	}, [stripe, elements, onProviderLoad]);

	return <SubscriptionFormView />;
};

export default SubscriptionForm;
