import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { ApiError, TrackedTrain } from "../../types";
import TrackedTrainItem from "./TrackedTrainItem";
import ServiceModal from "../ServiceModal";

export interface TrackedTrainsListViewProps {
	loading: boolean;
	error: ApiError | null;
	activeTrackedTrains: TrackedTrain[];
	inactiveTrackedTrains: TrackedTrain[];
	selectedTrain: TrackedTrain | null;
	onSelect: (id: TrackedTrain | null) => void;
}

const TrackedTrainsListView: FunctionComponent<TrackedTrainsListViewProps> = ({
	loading,
	error,
	activeTrackedTrains,
	inactiveTrackedTrains,
	selectedTrain,
	onSelect,
}) => (
	<>
		{error && (
			<div className="tt-departure-error alert alert-danger">
				<b>Oops, it looks like something went wrong!</b>
				<br />
				{error.message}
			</div>
		)}

		<div className="table-responsive">
			<table className="table table-striped table-hover">
				<thead className="table-dark">
					<tr>
						<th>Tracked</th>
					</tr>
				</thead>
				<tbody>
					{loading && (
						<tr>
							<td>
								<div className="progress">
									<div className="progress-bar progress-bar-striped progress-bar-animated w-100" />
								</div>
							</td>
						</tr>
					)}
					{!loading &&
						!activeTrackedTrains.length &&
						!inactiveTrackedTrains.length && (
							<tr>
								<td className="text-center">
									You haven't tracked any trains! Click{" "}
									<Link to="/departures">here</Link> to view
									the latest departures.
								</td>
							</tr>
						)}
					{activeTrackedTrains.map((t) => (
						<TrackedTrainItem
							key={t.trainId}
							trackedTrain={t}
							onClick={() => onSelect(t)}
						/>
					))}
				</tbody>
				{inactiveTrackedTrains.length > 0 && (
					<>
						<thead className="bg-secondary">
							<tr>
								<th>History</th>
							</tr>
						</thead>
						<tbody>
							{inactiveTrackedTrains.map((t) => (
								<TrackedTrainItem
									key={t.trainId}
									trackedTrain={t}
								/>
							))}
						</tbody>
					</>
				)}
			</table>
		</div>

		{selectedTrain && (
			<ServiceModal
				serviceId={selectedTrain.trainId}
				origin={selectedTrain.origin.crs}
				destination={selectedTrain.destination.crs}
				onClose={() => onSelect(null)}
				isOpen
			/>
		)}
	</>
);

export default TrackedTrainsListView;
