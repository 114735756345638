import { useEffect, useRef } from "react";
import useAppDispatch from "./useAppDispatch";
import useAppSelector from "./useAppSelector";
import { fetchDepartures } from "../store/departures/effects";

const useDepartures = (
	originCrs: string | null,
	destinationCrs: string | null,
	from: Date | null,
	operator: string | null
) => {
	const {
		loading,
		error,
		items: departures,
		messages,
		nextToken,
	} = useAppSelector((x) => x.departures);
	const dispatch = useAppDispatch();
	const debounceRef = useRef<NodeJS.Timeout | null>(null);

	const loadDepartures = () => {
		if (!originCrs || !destinationCrs || loading) {
			return;
		}

		if (debounceRef.current) {
			clearTimeout(debounceRef.current);
		}

		debounceRef.current = setTimeout(() => {
			dispatch(
				fetchDepartures({
					originCrs,
					destinationCrs,
					from,
					operator,
					nextToken: null,
				})
			);
			debounceRef.current = null;
		}, 1000);
	};

	const loadMoreDepartures = () => {
		if (!originCrs || !destinationCrs || loading || !nextToken) {
			return;
		}

		dispatch(
			fetchDepartures({
				originCrs,
				destinationCrs,
				from,
				operator,
				nextToken,
			})
		);
	};

	useEffect(loadDepartures, [
		originCrs,
		destinationCrs,
		operator,
		from,
		dispatch,
	]);

	const canLoadMore = nextToken !== null;

	return {
		loading,
		error,
		departures,
		messages,
		canLoadMore,
		loadMoreDepartures,
	};
};

export default useDepartures;
