import { createAsyncThunk } from "@reduxjs/toolkit";
import { refreshToken as refreshTokenApi } from "../../api";
import { ApiError } from "../../types";
import { AuthData, getRefreshToken, setAuthData } from "../../utils/auth";

export const refreshToken = createAsyncThunk(
	"auth/refresh",
	async (_, thunkAPI) => {
		const token = getRefreshToken();
		if (!token) {
			return thunkAPI.rejectWithValue({
				error: "Unauthorized",
				message: "Missing refresh token",
			} as ApiError);
		}
		const response = await refreshTokenApi(token);
		if ("error" in response!) {
			const error = response as ApiError;
			return thunkAPI.rejectWithValue(error);
		}
		setAuthData(response as AuthData);
	}
);
