import { ChangeEventHandler, FunctionComponent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { Elements } from "@stripe/react-stripe-js";
import {
	Stripe,
	StripeElements,
	StripeElementsOptionsClientSecret,
	loadStripe,
} from "@stripe/stripe-js";
import Layout from "../../components/Layout";
import { Plan, PlanType } from "../Landing/plans";
import { ApiError, CreateAccountRequest } from "../../types";
import SubscriptionForm from "./SubscriptionForm";

const stripePromise = loadStripe(
	"pk_live_51OYSmNHyEadO7JnLoyKFfCCdDRWULYtMyOJBzQDwslLqqSmO44MOz0TucTDwaJgLY7NdUAYNQXcXvdSRlbJqYUyH00YUXIoLaW"
);

const appearance = {
	theme: "flat",
	variables: {
		fontFamily:
			' "Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
		fontLineHeight: "1.5",
		borderRadius: "10px",
		colorBackground: "#F6F8FA",
		accessibleColorOnColorPrimary: "#262626",
	},
	rules: {
		".Block": {
			backgroundColor: "var(--colorBackground)",
			boxShadow: "none",
			padding: "12px",
		},
		".Input": {
			padding: "12px",
		},
		".Input:disabled, .Input--invalid:disabled": {
			color: "lightgray",
		},
		".Tab": {
			padding: "10px 12px 8px 12px",
			border: "none",
		},
		".Tab:hover": {
			border: "none",
			boxShadow:
				"0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)",
		},
		".Tab--selected, .Tab--selected:focus, .Tab--selected:hover": {
			border: "none",
			backgroundColor: "#fff",
			boxShadow:
				"0 0 0 1.5px var(--colorPrimaryText), 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)",
		},
		".Label": {
			fontWeight: "500",
		},
	},
};

export interface CreateAccountViewProps {
	plans: Plan[];
	plan: Plan;
	status: "initial" | "payment" | "done";
	error: ApiError | null;
	formData: CreateAccountRequest;
	canContinue: boolean;
	clientSecret: string | null;
	loading: boolean;
	sendingOtpStatus: "initial" | "requesting" | "requested" | "error";
	onChangePlan: (type: PlanType) => void;
	onSubmit: () => void;
	onChange: ChangeEventHandler;
	onStripeLoad: (
		stripe: Stripe | null,
		elements: StripeElements | null
	) => void;
	onVerifyPhoneNumber: () => void;
	onCloseError: () => void;
}

const CreateAccountView: FunctionComponent<CreateAccountViewProps> = ({
	plans,
	plan,
	error,
	status,
	formData,
	canContinue,
	clientSecret,
	loading,
	sendingOtpStatus,
	onChangePlan,
	onSubmit,
	onChange,
	onStripeLoad,
	onVerifyPhoneNumber,
	onCloseError,
}) => (
	<Layout>
		<div className="container py-5">
			<div className="row">
				<div className="col-lg-7 offset-lg-1">
					<h1 className="display-4 ts-4">Sign up</h1>
					<p>Create your TrainTrack account.</p>

					<hr />

					{!!error && !error.paramName && (
						<div
							className="alert alert-danger alert-dismissible show"
							role="alert"
						>
							{error.message}
							<button
								type="button"
								className="btn-close"
								aria-label="Close"
								onClick={onCloseError}
							></button>
						</div>
					)}
				</div>
			</div>
			<div className="row mb-4">
				<div className={classNames("col-lg-7 offset-lg-1")}>
					{status === "initial" && (
						<div className="row">
							<div className="col-md-6">
								<div className="mb-3">
									<label
										htmlFor="signup.firstname"
										className="form-label"
									>
										First name
									</label>
									<input
										type="text"
										className="form-control"
										id="signup.firstname"
										name="signup.firstname"
										placeholder="Your first name"
										autoComplete="given-name"
										value={formData.firstName}
										onChange={onChange}
									/>
									{error?.paramName === "firstName" && (
										<div className="text-danger">
											{error.message}
										</div>
									)}
								</div>
							</div>
							<div className="col-md-6">
								<div className="mb-3">
									<label
										htmlFor="signup.lastname"
										className="form-label"
									>
										Last name
									</label>
									<input
										type="text"
										className="form-control"
										id="signup.lastname"
										name="signup.lastname"
										placeholder="Your last name"
										autoComplete="family-name"
										value={formData.lastName}
										onChange={onChange}
									/>
									{error?.paramName === "lastName" && (
										<div className="text-danger">
											{error.message}
										</div>
									)}
								</div>
							</div>
							<div className="col-md-8">
								<div className="mb-3">
									<label
										htmlFor="signup.phonenumber"
										className="form-label"
									>
										Phone number
									</label>
									<div className="input-group">
										<input
											type="text"
											className="form-control"
											id="signup.phonenumber"
											name="signup.phonenumber"
											placeholder="Your phone number"
											autoComplete="tel"
											value={formData.phoneNumber}
											onChange={onChange}
										/>
										<button
											type="button"
											className="btn btn-outline-success"
											onClick={onVerifyPhoneNumber}
										>
											{sendingOtpStatus ===
											"requesting" ? (
												<div
													className="spinner-border"
													role="status"
												>
													<span className="visually-hidden">
														Loading...
													</span>
												</div>
											) : (
												"Verify"
											)}
										</button>
									</div>
									{(error?.paramName === "phoneNumber" ||
										error?.paramName ===
											"oneTimePasscode") && (
										<div className="text-danger">
											{error.message}
										</div>
									)}
									{!error &&
										sendingOtpStatus === "initial" && (
											<div className="text-muted fs-6">
												Click verify to be sent a
												verification code.
											</div>
										)}
								</div>
							</div>
							<div className="col-md-4">
								<div className="mb-3">
									<label
										htmlFor="signup.oneTimePasscode"
										className="form-label"
									>
										Verification code
									</label>
									<input
										type="text"
										className="form-control"
										id="signup.oneTimePasscode"
										name="signup.oneTimePasscode"
										placeholder="123456"
										value={formData.oneTimePasscode}
										onChange={onChange}
										disabled={
											sendingOtpStatus === "initial"
										}
									/>
								</div>
							</div>
							<div className="col-md-6">
								<div className="mb-3">
									<label
										htmlFor="signup.addressLine1"
										className="form-label"
									>
										Address line 1
									</label>
									<input
										type="text"
										className="form-control"
										id="signup.addressLine1"
										name="signup.addressLine1"
										placeholder="123 Main Street"
										autoComplete="billing address-line1"
										value={formData.address.lines[0]}
										onChange={onChange}
									/>
									{error?.paramName === "addressLine1" && (
										<div className="text-danger">
											{error.message}
										</div>
									)}
								</div>
							</div>
							<div className="col-md-6">
								<div className="mb-3">
									<label
										htmlFor="signup.addressLine2"
										className="form-label"
									>
										Address line 2
									</label>
									<input
										type="text"
										className="form-control"
										id="signup.addressLine2"
										name="signup.addressLine2"
										placeholder="Flat 404"
										autoComplete="billing address-line2"
										value={formData.address.lines[1]}
										onChange={onChange}
									/>
									{error?.paramName === "addressLine2" && (
										<div className="text-danger">
											{error.message}
										</div>
									)}
								</div>
							</div>
							<div className="col-md-6">
								<div className="mb-3">
									<label
										htmlFor="signup.city"
										className="form-label"
									>
										City
									</label>
									<input
										type="text"
										className="form-control"
										id="signup.city"
										name="signup.city"
										placeholder="Westminster"
										autoComplete="billing"
										value={formData.address.city}
										onChange={onChange}
									/>
									{error?.paramName === "addressCity" && (
										<div className="text-danger">
											{error.message}
										</div>
									)}
								</div>
							</div>
							<div className="col-md-6">
								<div className="mb-3">
									<label
										htmlFor="signup.postcode"
										className="form-label"
									>
										Postcode
									</label>
									<input
										type="text"
										className="form-control"
										id="signup.postcode"
										name="signup.postcode"
										placeholder="SW1A 1AA"
										autoComplete="billing postal-code"
										value={formData.address.postcode}
										onChange={onChange}
									/>
									{error?.paramName === "addressPostcode" && (
										<div className="text-danger">
											{error.message}
										</div>
									)}
								</div>
							</div>
						</div>
					)}
					{status === "payment" && (
						<Elements
							stripe={stripePromise}
							options={
								{
									clientSecret,
									appearance,
								} as StripeElementsOptionsClientSecret
							}
						>
							<SubscriptionForm onProviderLoad={onStripeLoad} />
						</Elements>
					)}
				</div>
				<div className="col-lg-3">
					{status === "initial" && (
						<div className="mb-3">
							<label htmlFor="signup.plan" className="form-label">
								Plan
							</label>
							<select
								className="form-select"
								id="signup.plan"
								value={plan.type}
								onChange={(e) =>
									onChangePlan(e.target.value as PlanType)
								}
							>
								{plans.map((p) => (
									<option key={p.type} value={p.type}>
										{p.displayName}
									</option>
								))}
							</select>
						</div>
					)}
					<dl>
						{status !== "initial" && (
							<>
								<dt>Plan</dt>
								<dd>{plan.displayName}</dd>
							</>
						)}
						<dt>Price</dt>
						<dd>{plan.price === 0 ? "Free" : "£" + plan.price}</dd>
						<dt>Quota</dt>
						<dd>
							Track up to {plan.quota.trackTrains}
							{" trains, "}
							{plan.type === PlanType.Trial
								? "in your first 30 days"
								: "per month"}
						</dd>
					</dl>
				</div>
			</div>
			<div className="row">
				<div className="col-lg-10 offset-lg-1 d-flex justify-content-end">
					<button
						className="btn btn-primary"
						onClick={onSubmit}
						disabled={!canContinue}
					>
						{loading ? (
							<div className="spinner-border" role="status">
								<span className="visually-hidden">
									Loading...
								</span>
							</div>
						) : (
							<>
								Next <FontAwesomeIcon icon={faArrowRightLong} />
							</>
						)}
					</button>
				</div>
			</div>
		</div>
	</Layout>
);

export default CreateAccountView;
