import { createAsyncThunk } from "@reduxjs/toolkit";
import { getRecentSearches } from "../../api";
import { ApiError, RecentSearch } from "../../types";

export const fetchRecentSearches = createAsyncThunk<RecentSearch[] | undefined>(
	"searches/list",
	async (_, thunkAPI) => {
		const response = await getRecentSearches();
		if ("error" in response) {
			const error = response as ApiError;
			return thunkAPI.rejectWithValue(error);
		}
		return response as RecentSearch[];
	}
);
