import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./state";
import { fetchTrackedTrains } from "./effects";
import { ApiError } from "../../types";
import { stopTrackingTrain } from "../tracking/effects";

const trackedTrainsSlice = createSlice({
	name: "trackedTrains",
	initialState: initialState,
	reducers: {},
	extraReducers(builder) {
		builder.addCase(fetchTrackedTrains.pending, (state, action) => {
			state.loading = true;
		});
		builder.addCase(fetchTrackedTrains.fulfilled, (state, action) => {
			state.items = action.payload!;
			state.loading = false;
			state.error = null;
		});
		builder.addCase(fetchTrackedTrains.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload as ApiError;
		});
		builder.addCase(stopTrackingTrain.fulfilled, (state, action) => {
			const id = action.meta.arg.serviceId;
			state.items = state.items.filter((x) => x.trainId !== id);
		});
	},
});

export default trackedTrainsSlice.reducer;
