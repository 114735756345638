import { ApiError, TrackedTrain } from "../../types";

export interface TrackedTrainsState {
	loading: boolean;
	error: ApiError | null;
	items: TrackedTrain[];
}

export const initialState: TrackedTrainsState = {
	loading: false,
	error: null,
	items: [],
};
