import { createAsyncThunk } from "@reduxjs/toolkit";
import {
	trackTrain as trackTrainApi,
	stopTrackingTrain as stopTrackingTrainApi,
} from "../../api";
import { ApiError } from "../../types";
import { StopTrackingTrainAction, TrackTrainAction } from "./actions";

export const trackTrain = createAsyncThunk<void, TrackTrainAction>(
	"tracking/track",
	async (action, thunkAPI) => {
		const response = await trackTrainApi(
			action.originCrs,
			action.destinationCrs,
			action.serviceId
		);
		if (!response) {
			return;
		}
		const error = response as ApiError;
		return thunkAPI.rejectWithValue(error);
	}
);

export const stopTrackingTrain = createAsyncThunk<
	void,
	StopTrackingTrainAction
>("tracking/stop-tracking", async (action, thunkAPI) => {
	const response = await stopTrackingTrainApi(action.serviceId);
	if (!response) {
		return;
	}
	const error = response as ApiError;
	return thunkAPI.rejectWithValue(error);
});
