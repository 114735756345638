import { FunctionComponent, useState } from "react";
import DepartureListView from "./DepartureListView";
import { Departure } from "../../types";
import { useDepartures } from "../../hooks";

export interface DepartureListProps {
	fromCrs: string | null;
	toCrs: string | null;
	from: Date | null;
	operator: string | null;
}

const DepartureList: FunctionComponent<DepartureListProps> = ({
	fromCrs,
	toCrs,
	from,
	operator,
}) => {
	const [selectedDeparture, setSelectedDeparture] =
		useState<Departure | null>(null);

	const {
		loading,
		departures,
		error,
		messages,
		canLoadMore,
		loadMoreDepartures,
	} = useDepartures(fromCrs, toCrs, from, operator);

	return (
		<DepartureListView
			loading={loading}
			error={error}
			departures={departures}
			warnings={messages}
			origin={fromCrs ?? ""}
			destination={toCrs ?? ""}
			canLoadMore={canLoadMore}
			selected={selectedDeparture}
			onSelect={setSelectedDeparture}
			onLoadMore={loadMoreDepartures}
		/>
	);
};

export default DepartureList;
