import { FunctionComponent, PropsWithChildren, useEffect, useRef } from "react";
import { Tooltip } from "bootstrap";

export interface ToolTipProps extends PropsWithChildren {
	text: string;
	placement?: "top" | "bottom" | "left" | "right";
}

const ToolTip: FunctionComponent<ToolTipProps> = ({
	text,
	placement = "top",
	children,
}) => {
	const tooltipRef = useRef<HTMLSpanElement | null>(null);

	useEffect(() => {
		if (!tooltipRef.current) {
			return;
		}

		const tooltip = new Tooltip(tooltipRef.current, {
			title: text,
			placement: placement,
			trigger: "hover",
		});

		return () => {
			tooltip.dispose();
		};
	}, [tooltipRef, text, placement]);

	return (
		<span
			className="d-inline-block"
			tabIndex={0}
			title={text}
			ref={tooltipRef}
		>
			{children}
		</span>
	);
};

export default ToolTip;
