import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./state";
import { ApiError } from "../../types";
import { fetchRecentSearches } from "./effects";

const searchesSlice = createSlice({
	name: "departures",
	initialState: initialState,
	reducers: {},
	extraReducers(builder) {
		builder.addCase(fetchRecentSearches.pending, (state, action) => {
			state.loading = true;
		});
		builder.addCase(fetchRecentSearches.fulfilled, (state, action) => {
			state.items = action.payload!;
			state.loading = false;
			state.error = null;
		});
		builder.addCase(fetchRecentSearches.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload as ApiError;
		});
	},
});

export default searchesSlice.reducer;
