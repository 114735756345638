import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./state";
import { fetchDepartures } from "./effects";
import { ApiError } from "../../types";
import { stopTrackingTrain, trackTrain } from "../tracking/effects";

const departuresSlice = createSlice({
	name: "departures",
	initialState: initialState,
	reducers: {},
	extraReducers(builder) {
		builder.addCase(fetchDepartures.pending, (state, action) => {
			state.originCrs = action.meta.arg.originCrs;
			state.destinationCrs = action.meta.arg.destinationCrs;
			state.loading = true;
		});
		builder.addCase(fetchDepartures.fulfilled, (state, action) => {
			const { services, messages, locationName, nextToken, operators } =
				action.payload!;
			state.messages = messages;
			state.originName = locationName;
			state.loading = false;
			state.error = null;
			state.nextToken = nextToken;

			if (action.meta.arg.nextToken) {
				state.items = [...state.items]
					.concat(services)
					.filter(
						(value, index, array) =>
							array.map((x) => x.id).indexOf(value.id) === index
					);
				state.operators = [...state.operators]
					.concat(operators)
					.filter(
						(value, index, array) =>
							array.map((x) => x.code).indexOf(value.code) ===
							index
					);
			} else {
				state.items = services;
				state.operators = operators;
			}
		});
		builder.addCase(fetchDepartures.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload as ApiError;
		});
		builder.addCase(trackTrain.fulfilled, (state, action) => {
			const id = action.meta.arg.serviceId;
			const item = state.items.find((x) => x.id === id);
			if (item) {
				item.tracked = true;
			}
		});
		builder.addCase(stopTrackingTrain.fulfilled, (state, action) => {
			const id = action.meta.arg.serviceId;
			const item = state.items.find((x) => x.id === id);
			if (item) {
				item.tracked = false;
			}
		});
	},
});

export default departuresSlice.reducer;
