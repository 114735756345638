import { ApiError } from "../../types";
import { getAccessToken } from "../../utils/auth";

export interface AuthState {
	loading: boolean;
	error: ApiError | null;
	isLoggedIn: boolean;
}

export const initialState: AuthState = {
	loading: false,
	error: null,
	isLoggedIn: !!getAccessToken(),
};
