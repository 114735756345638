import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./state";
import { refreshToken } from "./effects";
import { ApiError } from "../../types";

const authSlice = createSlice({
	name: "auth",
	initialState: initialState,
	reducers: {
		setIsLoggedIn: (state) => {
			state.isLoggedIn = true;
		},
		setLoggedOut: (state) => {
			state.isLoggedIn = false;
		},
	},
	extraReducers(builder) {
		builder.addCase(refreshToken.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(refreshToken.fulfilled, (state) => {
			state.loading = false;
			state.error = null;
			state.isLoggedIn = true;
		});
		builder.addCase(refreshToken.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload as ApiError;
			if (state.error.error === "Unauthorized") {
				state.isLoggedIn = false;
			}
		});
	},
});

export default authSlice.reducer;
export const { setIsLoggedIn, setLoggedOut } = authSlice.actions;
