import { ApiError } from "../../types";

export interface CreateAccountState {
	loading: boolean;
	sendingOtpStatus: "initial" | "requesting" | "requested" | "error";
	error: ApiError | null;
	status: "initial" | "payment" | "done";
	subscriptionId: string | null;
	clientSecret: string | null;
}

export const initialState: CreateAccountState = {
	loading: false,
	sendingOtpStatus: "initial",
	error: null,
	status: "initial",
	subscriptionId: null,
	clientSecret: null,
};
