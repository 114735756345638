import { FunctionComponent, PropsWithChildren, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { getAccessToken } from "../../utils/auth";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { refreshToken } from "../../store/auth/effects";

const anonymousPaths = [
	"/",
	"/login",
	"/verify-login-otp",
	"/logout",
	"/signup/[\\w]+",
	"/privacy-policy",
	"/cookie-policy",
];

const AuthContainer: FunctionComponent<PropsWithChildren<{}>> = ({
	children,
}) => {
	const { pathname } = useLocation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { loading, isLoggedIn } = useAppSelector((x) => x.auth);

	useEffect(() => {
		const auth = () => {
			if (anonymousPaths.find((p) => pathname.match(`^${p}$`))) {
				return;
			}
			const accessToken = getAccessToken();
			if (accessToken) {
				// Is logged in.
				return;
			}

			if (!loading) {
				dispatch(refreshToken());
			}
		};

		const interval = setInterval(auth, 10000);

		return () => {
			clearInterval(interval);
		};
	}, [pathname, navigate, dispatch, loading]);

	useEffect(() => {
		if (anonymousPaths.find((p) => pathname.match(`^${p}$`))) {
			return;
		}
		if (!loading && !isLoggedIn) {
			navigate("/login");
		}
	}, [loading, isLoggedIn, navigate, pathname]);

	return <>{children}</>;
};

export default AuthContainer;
