import { ApiError, Service } from "../../types";

export interface ServicesState {
	items: Service[];
	loading: boolean;
	error: ApiError | null;
}

export const initialState: ServicesState = {
	items: [],
	loading: false,
	error: null,
};
