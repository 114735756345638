import { createAsyncThunk } from "@reduxjs/toolkit";
import { getService } from "../../api";
import { LoadServiceAction } from "./actions";
import { ApiError, Service } from "../../types";

export const fetchService = createAsyncThunk<
	Service | undefined,
	LoadServiceAction
>("services/load", async ({ serviceId }, thunkAPI) => {
	const response = await getService(serviceId);
	if ("error" in response) {
		const error = response as ApiError;
		return thunkAPI.rejectWithValue(error);
	}
	return response as Service;
});
