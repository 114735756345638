import { FunctionComponent } from "react";
import { ApiError, Departure } from "../../types";
import DepartureItem from "./DepartureItem";

import "./DepartureListView.scss";
import ServiceModal from "../ServiceModal";

export interface DepartureListViewProps {
	loading: boolean;
	error: ApiError | null;
	departures: Departure[];
	warnings: string[];
	origin: string;
	destination: string;
	canLoadMore: boolean;
	selected: Departure | null;
	onSelect: (departure: Departure | null) => void;
	onLoadMore: () => void;
}

const DepartureListView: FunctionComponent<DepartureListViewProps> = ({
	loading,
	error,
	departures,
	warnings,
	origin,
	destination,
	canLoadMore,
	selected,
	onSelect,
	onLoadMore,
}) => {
	const renderTableBody = () => {
		if (loading) {
			<tr>
				<td>
					<div className="progress">
						<div className="progress-bar progress-bar-striped progress-bar-animated w-100" />
					</div>
				</td>
			</tr>;
		}

		if (!departures.length) {
			return (
				<tr>
					<td className="text-center">
						There are no services between these stations.
					</td>
				</tr>
			);
		}

		return departures.map((d) => (
			<DepartureItem
				key={d.id}
				departure={d}
				onClick={() => onSelect(d)}
			/>
		));
	};

	const renderAlerts = () => {
		if (error) {
			return (
				<div className="tt-departure-error alert alert-danger">
					<b>Oops, it looks like something went wrong!</b>
					<br />
					{error.message}
				</div>
			);
		}

		return warnings.map((msg, key) => (
			<div
				className="tt-departure-warning alert alert-warning"
				role="alert"
				key={key}
				dangerouslySetInnerHTML={{ __html: msg }}
			/>
		));
	};

	return (
		<>
			{renderAlerts()}

			<div className="table-responsive">
				<table className="table table-striped table-hover">
					<thead className="table-dark">
						<tr>
							<th>Departures</th>
						</tr>
					</thead>
					<tbody>{renderTableBody()}</tbody>
				</table>
			</div>

			{canLoadMore && (
				<div className="text-center">
					<button
						type="button"
						className="btn btn-outline-primary"
						onClick={onLoadMore}
					>
						{loading ? "Loading..." : "Load more"}
					</button>
				</div>
			)}

			<ServiceModal
				isOpen={selected !== null}
				serviceId={selected?.id ?? ""}
				origin={origin}
				destination={destination}
				onClose={() => onSelect(null)}
			/>
		</>
	);
};

export default DepartureListView;
