import { FunctionComponent } from "react";
import { useAppSelector } from "../../../hooks";
import { Option } from "react-bootstrap-typeahead/types/types";
import SelectOperatorView, {
	StationOperatorOption,
} from "./SelectOperatorView";

export interface SelectOperatorProps {
	operator: string | null;
	onChange: (operator: string | null) => void;
}

const SelectOperator: FunctionComponent<SelectOperatorProps> = ({
	operator,
	onChange,
}) => {
	const operators = useAppSelector((x) => x.departures.operators);

	const handleChange = (selected: Option[]) => {
		if (!selected.length) {
			onChange(null);
			return;
		}

		const option = selected[0] as StationOperatorOption;
		onChange(option.id);
	};

	const options = operators.map((o) => ({
		id: o.code,
		label: o.name,
		selected: o.code === operator,
	}));

	return <SelectOperatorView options={options} onChange={handleChange} />;
};

export default SelectOperator;
