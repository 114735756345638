import { createAsyncThunk } from "@reduxjs/toolkit";
import { getTrackedTrains } from "../../api";
import { ApiError, TrackedTrain } from "../../types";

export const fetchTrackedTrains = createAsyncThunk<TrackedTrain[] | undefined>(
	"trackedTrains/list",
	async (_, thunkAPI) => {
		const response = await getTrackedTrains();
		if ("error" in response) {
			const error = response as ApiError;
			return thunkAPI.rejectWithValue(error);
		}
		return response as TrackedTrain[];
	}
);
