import { FunctionComponent } from "react";
import { TrackedTrain, TrackedTrainTimes } from "../../types";

export interface TrackedTrainItemProps {
	trackedTrain: TrackedTrain;
	onClick?: () => void;
}

const TrackedTrainItem: FunctionComponent<TrackedTrainItemProps> = ({
	trackedTrain,
	onClick,
}) => {
	const getDisplayTime = (times: TrackedTrainTimes) => {
		if (times.actual) {
			return <span className="text-success">{times.actual}</span>;
		}
		if (!times.scheduled && !times.estimated) {
			return "";
		}
		if (
			times.estimated !== "On time" &&
			times.estimated !== times.scheduled
		) {
			let estimated = "";
			if (times.estimated) {
				estimated = "(" + times.estimated + ")";
			}

			return (
				<span className="text-danger">
					{times.scheduled} {estimated}
				</span>
			);
		}
		return <span className="text-success">{times.scheduled ?? "-"}</span>;
	};

	return (
		<tr className="tt-service-item" onClick={onClick}>
			<td>
				{trackedTrain.origin.name}{" "}
				{getDisplayTime(trackedTrain.departureTimes)}
				<br />
				{trackedTrain.destination.name}{" "}
				{getDisplayTime(trackedTrain.arrivalTimes)}
			</td>
		</tr>
	);
};

export default TrackedTrainItem;
