import { useEffect } from "react";
import useAppDispatch from "./useAppDispatch";
import useAppSelector from "./useAppSelector";
import { fetchTrackedTrains } from "../store/trackedTrains/effects";

const useTrackedTrains = () => {
	const {
		loading,
		error,
		items: trains,
	} = useAppSelector((x) => x.trackedTrains);
	const dispatch = useAppDispatch();

	const loadDepartures = () => {
		if (loading) {
			return;
		}

		dispatch(fetchTrackedTrains());
	};

	useEffect(loadDepartures, [dispatch]);

	return {
		loading,
		error,
		trains,
	};
};

export default useTrackedTrains;
