import {
	FunctionComponent,
	PropsWithChildren,
	createContext,
	useEffect,
	useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
	ACCESS_TOKEN_EXPIRY_STORAGE_NAME,
	ACCESS_TOKEN_STORAGE_NAME,
	REFRESH_TOKEN_STORAGE_NAME,
} from "../constants/storage";
import { BASE_URL } from "../constants/api";
import { ApiError } from "../types";

interface AuthData {
	accessToken: string;
	refreshToken: string;
	expiry: number;
}

interface AuthContextValue {
	accessToken: string | null;
	setAuthData: (data: AuthData | null) => void;
}

const AuthContext = createContext<AuthContextValue | null>(null);

export const getAuthData = (): AuthData | null => {
	const accessToken = localStorage.getItem(ACCESS_TOKEN_STORAGE_NAME);
	if (!accessToken) {
		return null;
	}
	const refreshToken = localStorage.getItem(REFRESH_TOKEN_STORAGE_NAME) ?? "";
	const expiry = Number(
		localStorage.getItem(ACCESS_TOKEN_EXPIRY_STORAGE_NAME)
	);
	return {
		accessToken,
		refreshToken,
		expiry,
	};
};

const getUTCTimestamp = (): number => {
	const now = new Date();
	const utc = Date.UTC(
		now.getUTCFullYear(),
		now.getUTCMonth(),
		now.getUTCDate(),
		now.getUTCHours(),
		now.getUTCMinutes(),
		now.getUTCSeconds()
	);
	return utc / 1000;
};

// Used to fetch the stored access token. If the token does not exist
// or has expired, null is returned.
const getAccessToken = (): string | null => {
	const accessToken = localStorage.getItem(ACCESS_TOKEN_STORAGE_NAME);
	if (!accessToken) {
		return null;
	}
	const expiry = Number(
		localStorage.getItem(ACCESS_TOKEN_EXPIRY_STORAGE_NAME)
	);
	if (expiry < getUTCTimestamp()) {
		return null;
	}
	return accessToken;
};

const getRefreshToken = (): string | null => {
	return localStorage.getItem(REFRESH_TOKEN_STORAGE_NAME);
};

const clearAuthStorage = () => {
	localStorage.removeItem(ACCESS_TOKEN_STORAGE_NAME);
	localStorage.removeItem(REFRESH_TOKEN_STORAGE_NAME);
	localStorage.removeItem(ACCESS_TOKEN_EXPIRY_STORAGE_NAME);
};

export const setAuthData = (authData: AuthData | null) => {
	if (!authData) {
		localStorage.removeItem(ACCESS_TOKEN_STORAGE_NAME);
		localStorage.removeItem(REFRESH_TOKEN_STORAGE_NAME);
		localStorage.removeItem(ACCESS_TOKEN_EXPIRY_STORAGE_NAME);

		return;
	}

	localStorage.setItem(ACCESS_TOKEN_STORAGE_NAME, authData.accessToken);
	localStorage.setItem(REFRESH_TOKEN_STORAGE_NAME, authData.refreshToken);
	localStorage.setItem(
		ACCESS_TOKEN_EXPIRY_STORAGE_NAME,
		authData.expiry.toString()
	);
};

const refreshAccessToken = async (): Promise<boolean> => {
	const refreshToken = getRefreshToken();
	if (!refreshToken) {
		return false;
	}
	console.log("Refreshing access tokens...");
	const url = BASE_URL + "/auth/refresh";
	const data = { refreshToken };

	try {
		const res = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		});
		switch (res.status) {
			case 200:
				const authData = (await res.json()) as AuthData;
				setAuthData(authData);
				return true;
			case 400:
			case 500:
				const errorData = (await res.json()) as ApiError;
				throw new Error(errorData.message);
			default:
				throw new Error(
					"API returned non-succes code while refreshing tokens: " +
						res.status
				);
		}
	} catch (e) {
		console.group("An error occured while fetching refreshing access");
		console.log("URL", url);
		console.error(e);
		console.groupEnd();

		return false;
	}
};

const AnonymousPaths = [
	"/",
	"/login",
	"/verify-login-otp",
	"/logout",
	"/signup/trial",
	"/signup/commuter",
	"/signup/enthusiast",
];

const AuthProvider: FunctionComponent<PropsWithChildren<{}>> = ({
	children,
}) => {
	const { pathname } = useLocation();
	const navigate = useNavigate();
	const [authData, setAuth] = useState<AuthData | null>(getAuthData());

	useEffect(() => {
		const auth = () => {
			if (AnonymousPaths.includes(pathname.toLowerCase())) {
				return;
			}
			const accessToken = getAccessToken();
			if (accessToken) {
				// Is logged in.
				return;
			}
			refreshAccessToken().then((ok) => {
				if (!ok) {
					clearAuthStorage();
					navigate("/login");
				} else {
					setAuth(getAuthData());
				}
			});
		};

		auth(); // Call on first load, then every 10 seconds.

		const interval = setInterval(auth, 10000);

		return () => {
			clearInterval(interval);
		};
	}, [pathname, navigate]);

	const value: AuthContextValue = {
		accessToken: authData?.accessToken || null,
		setAuthData: (data: AuthData | null) => {
			setAuthData(data); // Set storage
			setAuth(data); // Set state
		},
	};

	return (
		<AuthContext.Provider value={value}>{children}</AuthContext.Provider>
	);
};

export default AuthContext;
export { AuthProvider };
