import {
	ACCESS_TOKEN_EXPIRY_STORAGE_NAME,
	ACCESS_TOKEN_STORAGE_NAME,
	REFRESH_TOKEN_STORAGE_NAME,
} from "../constants/storage";

export interface AuthData {
	accessToken: string;
	refreshToken: string;
	expiry: number;
}

export const getAuthData = (): AuthData | null => {
	const accessToken = localStorage.getItem(ACCESS_TOKEN_STORAGE_NAME);
	if (!accessToken) {
		return null;
	}
	const refreshToken = localStorage.getItem(REFRESH_TOKEN_STORAGE_NAME) ?? "";
	const expiry = Number(
		localStorage.getItem(ACCESS_TOKEN_EXPIRY_STORAGE_NAME)
	);
	return {
		accessToken,
		refreshToken,
		expiry,
	};
};

export const setAuthData = (authData: AuthData | null) => {
	if (!authData) {
		localStorage.removeItem(ACCESS_TOKEN_STORAGE_NAME);
		localStorage.removeItem(REFRESH_TOKEN_STORAGE_NAME);
		localStorage.removeItem(ACCESS_TOKEN_EXPIRY_STORAGE_NAME);

		return;
	}

	localStorage.setItem(ACCESS_TOKEN_STORAGE_NAME, authData.accessToken);
	localStorage.setItem(REFRESH_TOKEN_STORAGE_NAME, authData.refreshToken);
	localStorage.setItem(
		ACCESS_TOKEN_EXPIRY_STORAGE_NAME,
		authData.expiry.toString()
	);
};

// Used to fetch the stored access token. If the token does not exist
// or has expired, null is returned.
export const getAccessToken = (): string | null => {
	const accessToken = localStorage.getItem(ACCESS_TOKEN_STORAGE_NAME);
	if (!accessToken) {
		return null;
	}
	const expiry = Number(
		localStorage.getItem(ACCESS_TOKEN_EXPIRY_STORAGE_NAME)
	);
	if (expiry < getUTCTimestamp()) {
		return null;
	}
	return accessToken;
};

export const getRefreshToken = (): string | null => {
	return localStorage.getItem(REFRESH_TOKEN_STORAGE_NAME);
};

export const clearAuthStorage = () => {
	localStorage.removeItem(ACCESS_TOKEN_STORAGE_NAME);
	localStorage.removeItem(REFRESH_TOKEN_STORAGE_NAME);
	localStorage.removeItem(ACCESS_TOKEN_EXPIRY_STORAGE_NAME);
};

const getUTCTimestamp = (): number => {
	const now = new Date();
	const utc = Date.UTC(
		now.getUTCFullYear(),
		now.getUTCMonth(),
		now.getUTCDate(),
		now.getUTCHours(),
		now.getUTCMinutes(),
		now.getUTCSeconds()
	);
	return utc / 1000;
};
