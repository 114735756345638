import { FunctionComponent } from "react";
import { ServiceCallingPoint } from "../../types";
import classNames from "classnames";

const isBefore = (a: string | null, b: string | null): boolean => {
	if (!a || !b) {
		return false;
	}

	const toNumber = (x: string) => {
		return Number(x.replace(":", ""));
	};

	return toNumber(a) < toNumber(b);
};

export interface CallingPointProps {
	callingPoint: ServiceCallingPoint;
}

const CallingPoint: FunctionComponent<CallingPointProps> = ({
	callingPoint: {
		name,
		scheduled,
		estimated,
		actual,
		platform,
		platformConfirmed,
		isCancelled: cancelled,
	},
}) => {
	const isOnTime =
		actual === "On time" ||
		estimated === "On time" ||
		isBefore(actual, scheduled);
	const isCancelled =
		actual === "Cancelled" || estimated === "Cancelled" || cancelled;

	const renderTime = () => {
		if (isCancelled) {
			return <span className="text-danger">Cancelled ({scheduled})</span>;
		}
		if (!isOnTime) {
			return (
				<span className="text-danger">
					{scheduled} ({estimated || actual})
				</span>
			);
		}
		return <span className="text-success">{scheduled}</span>;
	};

	const platformClasses = classNames("badge", "bg-light", {
		"text-muted": !platformConfirmed,
	});

	return (
		<li className="tt-service-location">
			<div className="tt-detail" />
			<div className="tt-info">
				<span className="tt-info-location-name">{name}</span>
				<br />
				{renderTime()}
			</div>
			{!!platform && (
				<div className="tt-platform">
					<div className={platformClasses}>Platform {platform}</div>
				</div>
			)}
		</li>
	);
};

export default CallingPoint;
