import { ChangeEventHandler, FormEventHandler, FunctionComponent } from "react";
import { ApiError } from "../../types";
import Layout from "../../components/Layout";
import { Link } from "react-router-dom";

export interface VerifyLoginOtpPageViewProps {
	oneTimePasscode: string;
	error: ApiError | null;
	loading: boolean;
	onChange: ChangeEventHandler<HTMLInputElement>;
	onSubmit: FormEventHandler;
	onCloseError: () => void;
}

const VerifyLoginOtpPageView: FunctionComponent<
	VerifyLoginOtpPageViewProps
> = ({ oneTimePasscode, error, loading, onChange, onSubmit, onCloseError }) => (
	<Layout>
		<div className="container py-5">
			<div className="row">
				<div className="col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-xl-4 offset-xl-4">
					{/* Title Section */}
					<h1 className="display-4 ts-4">Login</h1>
					<p>We've sent you a verification code to log in with.</p>

					<hr />
					{/* Error Section */}
					{!!error && !error.paramName && (
						<div
							className="alert alert-danger alert-dismissible show"
							role="alert"
						>
							{error.message}
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="alert"
								aria-label="Close"
								onClick={onCloseError}
							></button>
						</div>
					)}
					{/* Form Section */}
					<form className="d-flex flex-row" onSubmit={onSubmit}>
						<input
							type="number"
							name="oneTimePasscode"
							placeholder="Enter your one-time passcode..."
							className="form-control"
							aria-label="one time passcode"
							autoComplete="otp"
							value={oneTimePasscode}
							onChange={onChange}
						/>

						<div className="d-grid">
							<button
								type="submit"
								className="btn btn-success"
								disabled={loading}
							>
								Login
							</button>
						</div>
					</form>
					{error?.paramName && (
						<small className="text-danger">{error.message}</small>
					)}
					<p className="pt-4">
						Wrong phone number? Click <Link to="/login">here</Link>{" "}
						to go back.
					</p>
				</div>
			</div>
		</div>
	</Layout>
);

export default VerifyLoginOtpPageView;
