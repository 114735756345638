import { ApiError } from "../../types";

export interface TrackingState {
	loading: boolean;
	error: ApiError | null;
}

export const initialState: TrackingState = {
	loading: false,
	error: null,
};
