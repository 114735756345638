import { FunctionComponent } from "react";
import Layout from "../../components/Layout";

const PrivacyPolicy: FunctionComponent = () => (
	<Layout>
		<div className="container py-5">
			<div className="row">
				<div className="col-md-8 offset-md-2">
					<h1 className="display-5">Privacy Policy</h1>
					<p>
						Last updated on the 17<sup>th</sup> of January, 2024.
					</p>
					<hr />
					<p>
						This privacy notice for TrainTrack Solutions Limited
						(doing business as TrainTrack) ('
						<span className="fw-bold">we</span>', '
						<span className="fw-bold">us</span>', or '
						<span className="fw-bold">our</span>'), describes how
						and why we might collect, store, use, and/or share ('
						<span className="fw-bold">process</span>') your
						information when you use our services ('
						<span className="fw-bold">Services</span>'), such as
						when you:
					</p>
					<ul>
						<li>
							Visit our website at{" "}
							<a href="https://train-track.uk">
								https://train-track.uk
							</a>
							, or any website of ours that links to this privacy
							notice
						</li>
						<li>
							Engage with us in other related ways, including any
							sales, marketing, or events
						</li>
					</ul>
					<p>
						<span className="fw-bold">Questions or concerns?</span>{" "}
						Reading this privacy notice will help you understand
						your privacy rights and choices. If you do not agree
						with our policies and practices, please do not use our
						Services. If you still have any questions or concerns,
						please contact us at{" "}
						<a href="mailto:reece@train-track.uk">
							reece@train-track.uk
						</a>
						.
					</p>
					<h2 className="display-6 fs-2 mt-5 mb-4">
						Summary of key points
					</h2>
					<p className="fw-bold">
						This summary provides key points from our privacy
						notice, but you can find out more details about any of
						these topics by clicking the link following each key
						point or by using our{" "}
						<a href="#table-of-contents">table of contents</a> below
						to find the section you are looking for.
					</p>
					<p>
						<span className="fw-bold">
							What personal information do we process?
						</span>{" "}
						When you visit, use, or navigate our Services, we may
						process personal information depending on how you
						interact with us and the Services, the choices you make,
						and the products and features you use. Learn more about{" "}
						<a href="#personal-info">
							personal information you disclose to us.
						</a>
					</p>
					<p>
						<span className="fw-bold">
							Do we process any sensitive personal information?
						</span>{" "}
						We do not process sensitive personal information.
					</p>
					<p>
						<span className="fw-bold">
							Do we receive any information from third parties?
						</span>{" "}
						We do not receive any information from third parties.
					</p>
					<p>
						<span className="fw-bold">
							How do we process your information?
						</span>{" "}
						We process your information to provide, improve, and
						administer our Services, communicate with you, for
						security and fraud prevention, and to comply with law.
						We may also process your information for other purposes
						with your consent. We process your information only when
						we have a valid legal reason to do so. Learn more about{" "}
						<a href="#info-use">how we process your information.</a>
					</p>
					<p>
						<span className="fw-bold">
							In what situations and with which parties do we
							share personal information?
						</span>
						We may share information in specific situations and with
						specific third parties. Learn more about{" "}
						<a href="#share-with">
							when and with whom we share your personal
							information.
						</a>
					</p>
					<p>
						<span className="fw-bold">
							How do we keep your information safe?
						</span>{" "}
						We have organisational and technical processes and
						procedures in place to protect your personal
						information. However, no electronic transmission over
						the internet or information storage technology can be
						guaranteed to be 100% secure, so we cannot promise or
						guarantee that hackers, cybercriminals, or other
						unauthorised third parties will not be able to defeat
						our security and improperly collect, access, steal, or
						modify your information. Learn more about{" "}
						<a href="#info-safe">
							how we keep your information safe.
						</a>
					</p>
					<p>
						<span className="fw-bold">What are your rights?</span>{" "}
						Depending on where you are located geographically, the
						applicable privacy law may mean you have certain rights
						regarding your personal information. Learn more about{" "}
						<a href="#privacy-rights">your privacy rights</a>.
					</p>
					<p>
						<span className="fw-bold">
							How do you exercise your rights?
						</span>{" "}
						The easiest way to exercise your rights is by visiting{" "}
						<a href="https://train-track.uk">
							https://train-track.uk
						</a>
						, or by contacting us. We will consider and act upon any
						request in accordance with applicable data protection
						laws.
					</p>
					<p>
						Want to learn more about what we do with any information
						we collect?{" "}
						<a href="#table-of-contents">
							Review the privacy notice in full
						</a>
						.
					</p>
					<h2
						className="display-6 fs-2 mt-5 mb-4"
						id="table-of-contents"
					>
						Table of contents
					</h2>
					<ol>
						<li>
							<a href="#info-collect">
								What information do we collect?
							</a>
						</li>
						<li>
							<a href="#info-use">
								How do we process your information?
							</a>
						</li>
						<li>
							<a href="#legal-bases">
								What legal bases do we rely on to process your
								personal information?
							</a>
						</li>
						<li>
							<a href="#share-with">
								When and with whom do we share your personal
								information?
							</a>
						</li>
						<li>
							<a href="#cookies">
								Do we use cookies and other tracking
								technologies?
							</a>
						</li>
						<li>
							<a href="#info-retain">
								How long do we keep your information?
							</a>
						</li>
						<li>
							<a href="#info-safe">
								How long do we keep your information safe?
							</a>
						</li>
						<li>
							<a href="#privacy-rights">
								What are your privacy rights?
							</a>
						</li>
						<li>
							<a href="#do-not-track">
								Controls for do-not-track features
							</a>
						</li>
						<li>
							<a href="#updates">
								Do we make updates to this notice?
							</a>
						</li>
						<li>
							<a href="#contact">
								How can you contact us about this notice?
							</a>
						</li>
						<li>
							<a href="#request">
								How can you review, update, or delete the data
								we collect from you?
							</a>
						</li>
					</ol>
					<h3 className="display-6 fs-2 mt-5 mb-4" id="info-collect">
						What information do we collect?
					</h3>
					<h4 className="display-5 fs-5" id="personal-info">
						Personal information you disclose to us
					</h4>
					<p className="fst-italic">
						<span className="fw-bold">In short:</span> We collect
						personal information that you provide to us.
					</p>
					<p>
						We collect personal information that you voluntarily
						provide to us when you register on the Services, express
						an interest in obtaining information about us or our
						products and Services, when you participate in
						activities on the Services, or otherwise when you
						contact us.
					</p>
					<p>
						<span className="fw-bold">
							Personal Information Provided by You.
						</span>{" "}
						The personal information that we collect depends on the
						context of your interactions with us and the Services,
						the choices you make, and the products and features you
						use. The personal information we collect may include the
						following:
					</p>
					<ul>
						<li>names</li>
						<li>phone numbers</li>
						<li>billing addresses</li>
					</ul>
					<p>
						<span className="fw-bold">Sensitive Information.</span>{" "}
						We do not process sensitive information.
					</p>
					<p>
						<span className="fw-bold">Payment Data.</span> We may
						collect data necessary to process your payment if you
						make purchases, such as your payment instrument number,
						and the security code associated with your payment
						instrument. All payment data is stored by Stripe. You
						may find their privacy notice link(s) here:{" "}
						<a href="https://stripe.com/privacy">
							https://stripe.com/privacy
						</a>
						.
					</p>
					<p>
						All personal information that you provide to us must be
						true, complete, and accurate, and you must notify us of
						any changes to such personal information.
					</p>
					<h3 className="display-6 fs-2 mt-5 mb-4" id="info-use">
						How do we process your information?
					</h3>
					<p className="fst-italic">
						<span className="fw-bold">In short:</span> We process
						your information to provide, improve, and administer our
						Services, communicate with you, for security and fraud
						prevention, and to comply with law. We may also process
						your information for other purposes with your consent.
					</p>
					<p className="fw-bold">
						We process your personal information for a variety of
						reasons, depending on how you interact with our
						Services, including:
					</p>
					<ul>
						<li>
							<span className="fw-bold">
								To facilitate account creation and
								authentication and otherwise manage user
								accounts.
							</span>{" "}
							We may process your information so you can create
							and log in to your account, as well as keep your
							account in working order.
						</li>
						<li>
							<span className="fw-bold">
								To deliver and facilitate delivery of services
								to the user.
							</span>{" "}
							We may process your information to provide you with
							the requested service.
						</li>
						<li>
							<span className="fw-bold">
								To fulfil and manage your orders.
							</span>{" "}
							We may process your information to fulfil and manage
							your orders, payments, returns, and exchanges made
							through the Services.
						</li>
						<li>
							<span className="fw-bold">
								To save or protect an individual's vital
								interest.
							</span>{" "}
							We may process your information when necessary to
							save or protect an individual’s vital interest, such
							as to prevent harm.
						</li>
					</ul>
					<h3 className="display-6 fs-2 mt-5 mb-4" id="legal-bases">
						What legal bases do we rely on to process your
						information?
					</h3>
					<p className="fst-italic">
						<span className="fw-bold">In short:</span> We only
						process your personal information when we believe it is
						necessary and we have a valid legal reason (i.e. legal
						basis) to do so under applicable law, like with your
						consent, to comply with laws, to provide you with
						services to enter into or fulfil our contractual
						obligations, to protect your rights, or to fulfil our
						legitimate business interests.
					</p>
					<p>
						The General Data Protection Regulation (GDPR) and UK
						GDPR require us to explain the valid legal bases we rely
						on in order to process your personal information. As
						such, we may rely on the following legal bases to
						process your personal information:
					</p>
					<ul>
						<li>
							<span className="fw-bold">Consent.</span> We may
							process your information if you have given us
							permission (i.e. consent) to use your personal
							information for a specific purpose. You can withdraw
							your consent at any time. Learn more about{" "}
							<a href="withdraw-consent">
								withdrawing your consent
							</a>
							.
						</li>
						<li>
							<span className="fw-bold">
								Performance of a Contract.
							</span>{" "}
							We may process your personal information when we
							believe it is necessary to fulfil our contractual
							obligations to you, including providing our Services
							or at your request prior to entering into a contract
							with you.
						</li>
						<li>
							<span className="fw-bold">Legal Obligations.</span>{" "}
							We may process your information where we believe it
							is necessary for compliance with our legal
							obligations, such as to cooperate with a law
							enforcement body or regulatory agency, exercise or
							defend our legal rights, or disclose your
							information as evidence in litigation in which we
							are involved.
						</li>
						<li>
							<span className="fw-bold">Vital Interests.</span> We
							may process your information where we believe it is
							necessary to protect your vital interests or the
							vital interests of a third party, such as situations
							involving potential threats to the safety of any
							person.
						</li>
					</ul>
					<p>
						In legal terms, we are generally the 'data controller'
						under European data protection laws of the personal
						information described in this privacy notice, since we
						determine the means and/or purposes of the data
						processing we perform. This privacy notice does not
						apply to the personal information we process as a 'data
						processor' on behalf of our customers. In those
						situations, the customer that we provide services to and
						with whom we have entered into a data processing
						agreement is the 'data controller' responsible for your
						personal information, and we merely process your
						information on their behalf in accordance with your
						instructions. If you want to know more about our
						customers' privacy practices, you should read their
						privacy policies and direct any questions you have to
						them.
					</p>
					<h3 className="display-6 fs-2 mt-5 mb-4" id="share-with">
						When and with whom do we share your personal
						information?
					</h3>
					<p className="fst-italic">
						<span className="fw-bold">In short:</span> We may share
						information in specific situations described in this
						section and/or with the following third parties.
					</p>
					<p>
						We may need to share your personal information in the
						following situations:
					</p>
					<ul>
						<li>
							<span className="fw-bold">Business Transfers.</span>{" "}
							We may share or transfer your information in
							connection with, or during negotiations of, any
							merger, sale of company assets, financing, or
							acquisition of all or a portion of our business to
							another company.
						</li>
					</ul>
					<h3 className="display-6 fs-2 mt-5 mb-4" id="cookies">
						Do we use cookies and other tracking technologies?
					</h3>
					<p className="fst-italic">
						<span className="fw-bold">In short:</span> We may use
						cookies and other tracking technologies to collect and
						store your information.
					</p>
					<p>
						We may use cookies and similar tracking technologies
						(like web beacons and pixels) to access or store
						information. Specific information about how we use such
						technologies and how you can refuse certain cookies is
						set out in our Cookie Notice.
					</p>
					<h3 className="display-6 fs-2 mt-5 mb-4" id="info-retain">
						How long do we keep your information?
					</h3>
					<p className="fst-italic">
						<span className="fw-bold">In short:</span> We keep your
						information for as long as necessary to fulfil the
						purposes outlined in this privacy notice unless
						otherwise required by law.
					</p>
					<p>
						We will only keep your personal information for as long
						as it is necessary for the purposes set out in this
						privacy notice, unless a longer retention period is
						required or permitted by law (such as tax, accounting,
						or other legal requirements). No purpose in this notice
						will require us keeping your personal information for
						longer than the period of time in which users have an
						account with us.
					</p>
					<p>
						When we have no ongoing legitimate business need to
						process your personal information, we will either delete
						or anonymise such information, or, if this is not
						possible (for example, because your personal information
						has been stored in backup archives), then we will
						securely store your personal information and isolate it
						from any further processing until deletion is possible.
					</p>
					<h3 className="display-6 fs-2 mt-5 mb-4" id="info-safe">
						How do we keep your information safe?
					</h3>
					<p className="fst-italic">
						<span className="fw-bold">In short:</span> We aim to
						protect your personal information through a system of
						organisational and technical security measures.
					</p>
					<p>
						We have implemented appropriate and reasonable technical
						and organisational security measures designed to protect
						the security of any personal information we process.
						However, despite our safeguards and efforts to secure
						your information, no electronic transmission over the
						Internet or information storage technology can be
						guaranteed to be 100% secure, so we cannot promise or
						guarantee that hackers, cybercriminals, or other
						unauthorised third parties will not be able to defeat
						our security and improperly collect, access, steal, or
						modify your information. Although we will do our best to
						protect your personal information, transmission of
						personal information to and from our Services is at your
						own risk. You should only access the Services within a
						secure environment.
					</p>
					<h3
						className="display-6 fs-2 mt-5 mb-4"
						id="privacy-rights"
					>
						What are your privacy rights?
					</h3>
					<p className="fst-italic">
						<span className="fw-bold">In short:</span> In some
						regions, such as the European Economic Area (EEA),
						United Kingdom (UK), and Switzerland, you have rights
						that allow you greater access to and control over your
						personal information. You may review, change, or
						terminate your account at any time.
					</p>
					<p>
						In some regions (like the EEA, UK, and Switzerland), you
						have certain rights under applicable data protection
						laws. These may include the right (i) to request access
						and obtain a copy of your personal information, (ii) to
						request rectification or erasure; (iii) to restrict the
						processing of your personal information; (iv) if
						applicable, to data portability; and (v) not to be
						subject to automated decision-making. In certain
						circumstances, you may also have the right to object to
						the processing of your personal information. You can
						make such a request by contacting us by using the
						contact details provided in the section '
						<a href="#contact">
							How can you contact us about this notice?
						</a>
						' below.
					</p>
					<p>
						We will consider and act upon any request in accordance
						with applicable data protection laws.
					</p>
					<p>
						If you are located in the EEA or UK and you believe we
						are unlawfully processing your personal information, you
						also have the right to complain to your{" "}
						<a href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
							Member State data protection authority
						</a>{" "}
						or{" "}
						<a href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/">
							UK data protection authority
						</a>
						.
					</p>
					<p>
						If you are located in Switzerland, you may contact the{" "}
						<a href="https://www.edoeb.admin.ch/edoeb/en/home.html">
							Federal Data Protection and Information Commissioner
						</a>
						.
					</p>
					<p>
						<span className="fw-bold text-decoration-underline">
							Withdrawing your consent:
						</span>{" "}
						If we are relying on your consent to process your
						personal information, you have the right to withdraw
						your consent at any time. You can withdraw your consent
						at any time by contacting us by using the contact
						details provided in the section '
						<a href="#contact">
							How can you contact us about this notice?
						</a>
						' below.
					</p>
					<p>
						However, please note that this will not affect the
						lawfulness of the processing before its withdrawal nor,
						will it affect the processing of your personal
						information conducted in reliance on lawful processing
						grounds other than consent.
					</p>
					<h4 className="display-5 fs-5 my-4">Account Information</h4>
					<p>
						If you would at any time like to review or change the
						information in your account or terminate your account,
						you can:
					</p>
					<ul>
						<li>
							Contact us using the contact information provided.
						</li>
					</ul>
					<p>
						Upon your request to terminate your account, we will
						deactivate or delete your account and information from
						our active databases. However, we may retain some
						information in our files to prevent fraud, troubleshoot
						problems, assist with any investigations, enforce our
						legal terms and/or comply with applicable legal
						requirements.
					</p>
					<p>
						<span className="fw-bold text-decoration-underline">
							Cookies and similar technologies:
						</span>{" "}
						Most Web browsers are set to accept cookies by default.
						If you prefer, you can usually choose to set your
						browser to remove cookies and to reject cookies. If you
						choose to remove cookies or reject cookies, this could
						affect certain features or services of our Services.
					</p>
					<p>
						If you have questions or comments about your privacy
						rights, you may email us at{" "}
						<a href="mailto:reece@train-track.uk">
							reece@train-track.uk
						</a>
						.
					</p>
					<h3 className="display-6 fs-2 mt-5 mb-4" id="do-not-track">
						Controls for do-not-track features
					</h3>
					<p>
						Most web browsers and some mobile operating systems and
						mobile applications include a Do-Not-Track ('DNT')
						feature or setting you can activate to signal your
						privacy preference not to have data about your online
						browsing activities monitored and collected. At this
						stage no uniform technology standard for recognising and
						implementing DNT signals has been finalised. As such, we
						do not currently respond to DNT browser signals or any
						other mechanism that automatically communicates your
						choice not to be tracked online. If a standard for
						online tracking is adopted that we must follow in the
						future, we will inform you about that practice in a
						revised version of this privacy notice.
					</p>
					<h3 className="display-6 fs-2 mt-5 mb-4" id="updates">
						Do we make updates to this notice?
					</h3>
					<p className="fst-italic">
						<span className="fw-bold">In short:</span> Yes, we will
						update this notice as necessary to stay compliant with
						relevant laws.
					</p>
					<p>
						We may update this privacy notice from time to time. The
						updated version will be indicated by an updated
						'Revised' date and the updated version will be effective
						as soon as it is accessible. If we make material changes
						to this privacy notice, we may notify you either by
						prominently posting a notice of such changes or by
						directly sending you a notification. We encourage you to
						review this privacy notice frequently to be informed of
						how we are protecting your information.
					</p>
					<h3 className="display-6 fs-2 mt-5 mb-4" id="contact">
						How can you contact us about this notice?
					</h3>
					<p>
						If you have questions or comments about this notice, you
						may email us at{" "}
						<a href="mailto:reece@train-track.uk">
							reece@train-track.uk
						</a>{" "}
						or contact us by post at:
					</p>
					<p>
						TrainTrack Solutions Limited
						<br />
						Flat 408, Nelson House
						<br />
						Pimlico, SW1V 3NZ
						<br />
						United Kingdom
					</p>
					<p>
						If you are a resident in the United Kingdom, we are the
						'data controller' of your personal information. We have
						appointed Reece Russell to be our representative in the
						UK. You can contact them directly regarding our
						processing of your information, by email at{" "}
						<a href="mailto:reece@train-track.uk">
							reece@train-track.uk
						</a>
						, or by visiting{" "}
						<a href="https://train-track.uk">
							https://train-track.uk
						</a>
						.
					</p>
					<h3 className="display-6 fs-2 mt-5 mb-4" id="request">
						How can you review, update, or delete the data we
						collect from you?
					</h3>
					<p>
						Based on the applicable laws of your country, you may
						have the right to request access to the personal
						information we collect from you, change that
						information, or delete it. To request to review, update,
						or delete your personal information, please visit:{" "}
						<a href="https://train-track.uk">
							https://train-track.uk
						</a>
						.
					</p>
				</div>
			</div>
		</div>
	</Layout>
);

export default PrivacyPolicy;
