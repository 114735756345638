import { ChangeEventHandler, FunctionComponent, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import DeparturesView from "./DeparturesView";

interface RouteProps {
	from: string;
	to: string;
	[key: string]: string;
}

const Departures: FunctionComponent = () => {
	const { from, to } = useParams<RouteProps>();
	const [selectedOrigin, setSelectedOrigin] = useState<string | null>(
		from?.toUpperCase() ?? ""
	);
	const [selectedDestination, setSelectedDestination] = useState<
		string | null
	>(to?.toUpperCase() ?? "");
	const [selectedOperator, setSelectedOperator] = useState<string | null>(
		null
	);
	const [fromDate, setFromDate] = useState<Date>(new Date());
	const navigate = useNavigate();

	const handleSetOrigin = (crs: string | null) => {
		setSelectedOrigin(crs);

		if (crs) {
			let path = "/departures/" + crs;
			if (selectedDestination) {
				path += "/" + selectedDestination;
			}

			navigate(path);
		}
	};

	const handleSetDestination = (crs: string | null) => {
		setSelectedDestination(crs);

		if (crs) {
			navigate("/departures/" + selectedOrigin + "/" + crs);
		}
	};

	const handleSetFromDate: ChangeEventHandler<HTMLInputElement> = (e) => {
		const value = Date.parse(e.target.value);
		if (isNaN(value)) {
			return;
		}

		setFromDate(new Date(value));
	};

	return (
		<DeparturesView
			selectedOrigin={selectedOrigin}
			selectedDestination={selectedDestination}
			selectedOperator={selectedOperator}
			fromDate={fromDate}
			onSelectOrigin={handleSetOrigin}
			onSelectDestination={handleSetDestination}
			onSelectOperator={setSelectedOperator}
			onSetFromDate={handleSetFromDate}
		/>
	);
};

export default Departures;
