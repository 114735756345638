import { FunctionComponent } from "react";
import { ApiError, RecentSearch } from "../../types";
import ToolTip from "../ToolTip";
import moment from "moment";
import { Link } from "react-router-dom";

import "./RecentSearchesView.scss";

export interface RecentSearchesViewProps {
	loading: boolean;
	error: ApiError | null;
	searches: RecentSearch[];
}

const RecentSearchesView: FunctionComponent<RecentSearchesViewProps> = ({
	searches,
}) => {
	const getToolTipText = (s: RecentSearch) => {
		const time = moment(s.lastSearched).fromNow();

		return `Last searched ${time}`;
	};

	return (
		<>
			<ol className="tt-recent-searches">
				{searches.length > 0 ? (
					searches.map((s) => (
						<li
							key={s.origin.crs + s.destination.crs}
							className="tt-recent-search"
						>
							<ToolTip text={getToolTipText(s)}>
								<Link
									to={`/departures/${s.origin.crs}/${s.destination.crs}`}
								>
									{s.origin.name} to {s.destination.name}
								</Link>
							</ToolTip>
						</li>
					))
				) : (
					<li className="tt-recent-search">
						You don't have any recent searches. View departures{" "}
						<Link to="/departures">here</Link>.
					</li>
				)}
			</ol>
		</>
	);
};

export default RecentSearchesView;
