export enum PlanType {
	Trial = "trial",
	Casual = "casual",
	Commuter = "commuter",
}

export interface Plan {
	type: PlanType;
	displayName: string;
	price: number;
	features: string[];
	quota: {
		trackTrains: number;
	};
}

const plans: Plan[] = [
	{
		type: PlanType.Trial,
		displayName: "Trial",
		price: 0,
		features: [
			"Free for 30 days",
			"Platform confirmation alerts",
			"Train delay alerts",
			"Track up to 10 trains",
			"View live departures and delays",
		],
		quota: {
			trackTrains: 10,
		},
	},
	{
		type: PlanType.Casual,
		displayName: "Casual",
		price: 1.99,
		features: [
			"£1.99 per month",
			"Platform confirmation alerts",
			"Train delay alerts",
			"Track up to 20 trains per month",
			"View live departures and delays",
		],
		quota: {
			trackTrains: 20,
		},
	},
	{
		type: PlanType.Commuter,
		displayName: "Commuter",
		price: 4.99,
		features: [
			"£4.99 per month",
			"Platform confirmation alerts",
			"Train delay alerts",
			"Track up to 50 trains per month",
			"View live departures and delays",
		],
		quota: {
			trackTrains: 50,
		},
	},
];

export default plans;
