import { FunctionComponent } from "react";
import Layout from "../../components/Layout";
import plans from "./plans";

import "./Landing.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faDatabase,
	faExclamation,
	faMessage,
} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { Link } from "react-router-dom";

const Landing: FunctionComponent = () => {
	return (
		<Layout>
			<section className="tt-hero">
				<div className="container tt-hero-content">
					<div className="row">
						<div className="col-12 col-sm-6 pe-sm-5 py-5">
							<h1 className="mb-4">TrainTrack</h1>
							<p>
								TrainTrack is the only way to receive realtime
								notifications on the status of your train by
								SMS. Track your train and receive texts if your
								train is delayed, and when your platform is
								confirmed
							</p>
							<p className="mb-4">
								Want to see your platform before it's shown on
								the board?
							</p>
							<a
								className="btn btn-sm btn-primary"
								href="/#plans"
							>
								Start your trial
							</a>
						</div>
						<div className="d-none d-sm-block col-sm-6">
							<div className="tt-hero-image" />
						</div>
					</div>
				</div>
			</section>
			<section className="tt-about pt-5" id="about">
				<div className="mb-5 text-center">
					<h2 className="display-6">Why TrainTrack?</h2>
					<p>More about the TrainTrack platform</p>
				</div>
				<div className="container">
					<div className="row">
						<div className="col-md-4 px-5 pb-5 text-center">
							<div className="d-flex flex-row justify-content-center mb-4">
								<div className="tt-about-icon">
									<FontAwesomeIcon
										icon={faMessage}
										size="2x"
									/>
								</div>
							</div>

							<h4 className="display-5 fs-5 mb-3">
								Find your platform before anyone else
							</h4>

							<div className="tt-about-divider mb-3">
								<div />
							</div>

							<p className="fs-6">
								We'll send you a text with the platform your
								train will be at, before it appears on the
								departures board, or even Trainline.
							</p>
						</div>
						<div className="col-md-4 px-5 pb-5 text-center">
							<div className="d-flex flex-row justify-content-center mb-4">
								<div className="tt-about-icon">
									<FontAwesomeIcon
										icon={faExclamation}
										size="2x"
									/>
								</div>
							</div>

							<h4 className="display-5 fs-5 mb-3">
								Know about delays in realtime
							</h4>

							<div className="tt-about-divider mb-3">
								<div />
							</div>

							<p className="fs-6">
								With TrainTrack, you'll know if your train has
								been delayed, in real time. We'll send you a
								text with the exact time it'll be there.
							</p>
						</div>
						<div className="col-md-4 px-5 pb-5 text-center">
							<div className="d-flex flex-row justify-content-center mb-4">
								<div className="tt-about-icon">
									<FontAwesomeIcon
										icon={faDatabase}
										size="2x"
									/>
								</div>
							</div>

							<h4 className="display-5 fs-5 mb-3">
								We get our data from the source
							</h4>

							<div className="tt-about-divider mb-3">
								<div />
							</div>

							<p className="fs-6">
								TrainTrack is powered by the same data used by
								National Rail and Trainline, so you can be sure
								to we'll know where you train is.
							</p>
						</div>
					</div>
				</div>
			</section>
			<section className="tt-plans pt-5" id="plans">
				<div className="container">
					<div className="col-md-4 offset-md-4 text-center mb-5">
						<h2 className="display-6">Plans</h2>
						<p>View our plans</p>
					</div>
					<div className="row">
						{plans.map((plan, index) => (
							<div
								className={classNames(
									"col-md-6 col-lg-4 px-5 pb-5 d-flex flex-column justify-content-between",
									{
										"offset-md-3": index === 2,
										"offset-lg-0": index === 2,
									}
								)}
								key={plan.type}
							>
								<div className="mb-4">
									<h5 className="display-4 fs-4 text-center mb-3">
										{plan.displayName}
										<small className="text-muted fs-5">
											{plan.price === 0
												? "Free"
												: "£" + plan.price}
										</small>
									</h5>
									<hr />
									<ul className="list-group list-group-flush px-3">
										{plan.features.map((f, i) => (
											<li
												className="list-group-item fs-6"
												key={i}
											>
												{f}
											</li>
										))}
									</ul>
								</div>
								<div className="text-center">
									<Link
										className="btn btn-primary btn-sm hover:bg-light"
										to={"/signup/" + plan.type}
									>
										Choose plan
									</Link>
								</div>
							</div>
						))}
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default Landing;
