import { FunctionComponent, useState } from "react";
import TrackedTrainsListView from "./TrackedTrainsListView";
import { useTrackedTrains } from "../../hooks";
import { TrackedTrain } from "../../types";

const TrackedTrainsList: FunctionComponent = () => {
	const { trains, loading, error } = useTrackedTrains();
	const [selectedTrain, setSelectedTrain] = useState<TrackedTrain | null>(
		null
	);

	const activeTrackedTrains = trains.filter((x) => x.active);
	const inactiveTrackedTrains = trains.filter((x) => !x.active);

	return (
		<TrackedTrainsListView
			loading={loading}
			error={error}
			activeTrackedTrains={activeTrackedTrains}
			inactiveTrackedTrains={inactiveTrackedTrains}
			onSelect={setSelectedTrain}
			selectedTrain={selectedTrain}
		/>
	);
};

export default TrackedTrainsList;
