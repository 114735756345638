import { FunctionComponent } from "react";
import { useRecentSearches } from "../../hooks";
import RecentSearchesView from "./RecentSearchesView";

const RecentSearches: FunctionComponent = () => {
	const { loading, error, searches } = useRecentSearches();

	return (
		<RecentSearchesView
			loading={loading}
			error={error}
			searches={searches}
		/>
	);
};

export default RecentSearches;
