import { FunctionComponent, useEffect } from "react";
import ServiceModalView from "./ServiceModalView";
import { Service } from "../../types";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { fetchService } from "../../store/services/effects";
import { clearError } from "../../store/tracking";

export interface ServiceModalProps {
	isOpen: boolean;
	origin: string;
	destination: string;
	serviceId: string;
	onClose: () => void;
}

const ServiceModal: FunctionComponent<ServiceModalProps> = ({
	isOpen,
	origin,
	destination,
	serviceId,
	onClose,
}) => {
	const dispatch = useAppDispatch();
	const { loading: serviceLoading, items: services } = useAppSelector(
		(x) => x.services
	);
	const error = useAppSelector((x) => x.tracking.error);
	const service = services.find((x) => x.id === serviceId);

	useEffect(() => {
		if (serviceLoading || !isOpen) {
			return;
		}

		dispatch(fetchService({ serviceId }));

		// Disable exhaustive deps as we don't want to include the loading variable.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [serviceId, isOpen, dispatch]);

	const handleCloseError = () => {
		dispatch(clearError());
	};

	if (!isOpen) {
		return null;
	}

	let serviceDisplay: Service | null = null;
	if (service) {
		serviceDisplay = { ...service, callingPoints: [] };

		let reachedOrigin = false;
		for (let i = 0; i < service.callingPoints.length; i++) {
			const cp = { ...service.callingPoints[i] };
			if (cp.crs === origin) {
				reachedOrigin = true;
				cp.platform = service.platform;
			}
			if (reachedOrigin) {
				serviceDisplay.callingPoints.push(cp);
			}
			if (cp.crs === destination) {
				break;
			}
		}
	}

	return (
		<ServiceModalView
			service={serviceDisplay}
			loading={serviceLoading}
			error={error}
			origin={origin}
			destination={destination}
			onClose={onClose}
			onCloseError={handleCloseError}
		/>
	);
};

export default ServiceModal;
