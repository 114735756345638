import { FunctionComponent } from "react";
import Layout from "../../components/Layout";
import TrackedTrainsList from "../../components/TrackedTrainsList";
import RecentSearches from "../../components/RecentSearches";

const DashboardView: FunctionComponent = () => (
	<Layout>
		<div className="container py-5">
			<div className="row">
				<div className="col-xl-6 offset-xl-3">
					<h1 className="fs-3 mb-3">Your tracked trains</h1>
				</div>
			</div>
			<div className="row">
				<div className="col-xl-6 offset-xl-3">
					<TrackedTrainsList />
				</div>
				<div className="col-xl-3">
					<h4 className="fs-5">Your recent searches</h4>
					<hr />
					<RecentSearches />
				</div>
			</div>
		</div>
	</Layout>
);

export default DashboardView;
