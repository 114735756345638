import {
	ChangeEventHandler,
	FormEventHandler,
	FunctionComponent,
	useEffect,
	useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../constants/api";
import { ApiError } from "../../types";
import VerifyLoginOtpPageView from "./VerifyLoginOtpPageView";
import { PHONE_NUMBER_STORAGE_NAME } from "../../constants/storage";
import { useAuth } from "../../hooks";

import "./VerifyLoginOtpPage.scss";

interface Response {
	accessToken: string;
	refreshToken: string;
	expiry: number;
}

const VerifyLoginOtpPage: FunctionComponent = () => {
	const [oneTimePasscode, setOneTimePasscode] = useState("");
	const [error, setError] = useState<ApiError | null>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const navigate = useNavigate();
	const { login } = useAuth();

	const handleChange: ChangeEventHandler<HTMLInputElement> = ({
		target: { value },
	}) => {
		setOneTimePasscode(value);
	};

	const handleSubmit: FormEventHandler = (e) => {
		e.preventDefault();

		if (loading) {
			return;
		}

		console.log("Submitting verify OTP form...");

		const url = BASE_URL + "/login/otp";
		const data = {
			phoneNumber: localStorage.getItem(PHONE_NUMBER_STORAGE_NAME),
			oneTimePasscode: oneTimePasscode,
		};

		fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		})
			.then(async (res) => {
				switch (res.status) {
					case 200:
						const data = (await res.json()) as Response;
						login(data);
						localStorage.removeItem(PHONE_NUMBER_STORAGE_NAME);
						setError(null);
						setLoading(false);
						navigate("/dashboard");
						return;
					case 400:
						const errorData = await res.json();
						setError(errorData);
						setLoading(false);
						return;
					default:
						throw new Error(
							"API returned status code: " + res.status
						);
				}
			})
			.catch((e) => {
				console.group("An error occured when verifying OTP");
				console.log("URL", url);
				console.log("Data", data);
				console.error(e);
				console.groupEnd();

				setError({
					error: "Error",
					message:
						"An error occured while verifying your one time passcode. Please try again later.",
				});
				setLoading(false);
			});
	};

	const handleCloseError = () => setError(null);

	useEffect(() => {
		const phoneNumber = localStorage.getItem(PHONE_NUMBER_STORAGE_NAME);
		if (!phoneNumber) {
			console.log("Phone number not set, redirecting to login.");
			navigate("/login");
		}
	}, [navigate]);

	return (
		<VerifyLoginOtpPageView
			oneTimePasscode={oneTimePasscode}
			error={error}
			loading={loading}
			onChange={handleChange}
			onSubmit={handleSubmit}
			onCloseError={handleCloseError}
		/>
	);
};

export default VerifyLoginOtpPage;
