import { ChangeEventHandler, FunctionComponent } from "react";
import { SelectStationLocation } from "../../components";
import DepartureList from "../../components/DepartureList";
import Layout from "../../components/Layout";
import SelectOperator from "./SelectOperator";

export interface DeparturesViewProps {
	selectedOrigin: string | null;
	selectedDestination: string | null;
	selectedOperator: string | null;
	fromDate: Date;
	onSelectOrigin: (crs: string | null) => void;
	onSelectDestination: (crs: string | null) => void;
	onSelectOperator: (operator: string | null) => void;
	onSetFromDate: ChangeEventHandler<HTMLInputElement>;
}

const DeparturesView: FunctionComponent<DeparturesViewProps> = ({
	selectedOrigin,
	selectedDestination,
	selectedOperator,
	fromDate,
	onSelectOrigin,
	onSelectDestination,
	onSelectOperator,
	onSetFromDate,
}) => (
	<Layout>
		<div className="container py-5">
			<div className="row mb-3">
				<div className="col-xl-6 offset-xl-3 pb-3 border-bottom">
					<div className="row mb-2">
						<div className="col-md-6 mb-2 mb-md-0">
							<SelectStationLocation
								crs={selectedOrigin}
								onChange={onSelectOrigin}
							/>
						</div>
						<div className="col-md-6">
							<SelectStationLocation
								crs={selectedDestination}
								onChange={onSelectDestination}
							/>
						</div>
					</div>
					<div className="row mb-2">
						<div className="col-md-6 mb-2 mb-md-0">
							<input
								type="datetime-local"
								className="form-control"
								name="fromDate"
								value={fromDate.toISOString().slice(0, 16)}
								onChange={onSetFromDate}
							/>
						</div>
						<div className="col-md-6">
							<SelectOperator
								operator={selectedOperator}
								onChange={onSelectOperator}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-xl-6 offset-xl-3">
					<DepartureList
						fromCrs={selectedOrigin}
						toCrs={selectedDestination}
						operator={selectedOperator}
						from={fromDate}
					/>
				</div>
			</div>
		</div>
	</Layout>
);

export default DeparturesView;
