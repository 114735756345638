import { createAsyncThunk } from "@reduxjs/toolkit";
import { getDepartures } from "../../api";
import { LoadDeparturesAction } from "./actions";
import { ApiError, Departures } from "../../types";

export const fetchDepartures = createAsyncThunk<
	Departures | undefined,
	LoadDeparturesAction
>(
	"departures/list",
	async (
		{ originCrs, destinationCrs, nextToken, from, operator },
		thunkAPI
	) => {
		const response = await getDepartures(
			originCrs,
			destinationCrs,
			from,
			nextToken,
			operator
		);
		if ("error" in response) {
			const error = response as ApiError;
			return thunkAPI.rejectWithValue(error);
		}
		return response as Departures;
	}
);
