import {
	FunctionComponent,
	PropsWithChildren,
	useEffect,
	useRef,
	useState,
} from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../hooks";
import classNames from "classnames";

import "./Layout.scss";

export interface LayoutProps extends PropsWithChildren {}

const Layout: FunctionComponent<LayoutProps> = ({ children }) => {
	const { ok: isLoggedIn } = useAuth();
	const { pathname } = useLocation();
	const headerRef = useRef<HTMLElement>(null);
	const mainRef = useRef<HTMLElement>(null);
	const footerRef = useRef<HTMLElement>(null);
	const [mainMinHeight, setMainMinHeight] = useState(-1);
	const [collapsed, setCollapsed] = useState(false);

	const brandLink = isLoggedIn ? "/dashboard" : "/";

	const routes: NavigationProps[] = isLoggedIn
		? [
				{ text: "Dashboard", to: "/dashboard" },
				{ text: "Departures", to: "/departures" },
				{ text: "Logout", to: "/logout" },
		  ]
		: [{ text: "Login", to: "/login" }];

	// Used to ensure the footer is pushed to the bottom of the page if
	// the content in the main tag is not tall enough.
	useEffect(() => {
		if (!headerRef || !mainRef || !footerRef) {
			return;
		}

		const ensureMainHeight = () => {
			const headerHeight = headerRef.current!.clientHeight;
			const mainHeight = mainRef.current!.clientHeight;
			const footerHeight = footerRef.current!.clientHeight;
			const contentHeight = headerHeight + mainHeight + footerHeight;
			const viewportHeight = window.innerHeight;
			const height = viewportHeight - headerHeight - footerHeight;

			if (contentHeight < viewportHeight) {
				setMainMinHeight(height);
			} else if (contentHeight > mainMinHeight) {
				setMainMinHeight(height);
			}
		};

		ensureMainHeight();

		window.addEventListener("resize", ensureMainHeight);

		return () => {
			window.removeEventListener("resize", ensureMainHeight);
		};
	}, [headerRef, mainRef, footerRef, mainMinHeight]);

	useEffect(() => {
		if (mainMinHeight > 0 && mainRef) {
			mainRef.current!.style.minHeight = mainMinHeight + "px";
		}
	}, [mainMinHeight, mainRef]);

	useEffect(() => {
		const largeBreakpoint = 992;

		setCollapsed(window.innerWidth < largeBreakpoint);
	}, []);

	const navbarCollapseClasses = classNames(
		"navbar-collapse justify-content-end mt-2 mt-lg-0",
		{
			collapse: collapsed,
			"d-lg-flex": !collapsed,
		}
	);

	return (
		<>
			<header
				className="navbar navbar-expand-lg navbar-dark bg-primary"
				ref={headerRef}
			>
				<div className="container">
					<Link className="navbar-brand" to={brandLink}>
						Train Track
					</Link>
					<button
						className="navbar-toggler"
						type="button"
						onClick={() => setCollapsed(!collapsed)}
					>
						<span className="navbar-toggler-icon"></span>
					</button>
					<div className={navbarCollapseClasses}>
						<ul className="navbar-nav" role="navigation">
							{routes.map((r) => (
								<li
									className="nav-item bg-primary"
									key={r.text.toLowerCase().replace(" ", "-")}
								>
									<Link
										className={classNames("nav-link", {
											active: r.to === pathname,
										})}
										to={r.to}
									>
										{r.text}
									</Link>
								</li>
							))}
						</ul>
					</div>
				</div>
			</header>
			<main ref={mainRef}>{children}</main>
			<footer className="tt-footer py-5" ref={footerRef}>
				<div className="container">
					<div className="row">
						<div className="col-12">
							<h6 className="display-4 fs-3 mb-3">TrainTrack</h6>
						</div>
					</div>
					<div className="row">
						<div className="col-md-3">
							<p className="fs-5">
								&copy; {new Date().getFullYear()} TrainTrack
								Solutions Limited. All Rights Reserved.
							</p>
							<p className="fs-5">
								TrainTrack is registered and incorporated in
								England and Wales.
							</p>
						</div>
						<div className="offset-md-1 col-md-4">
							<ul className="tt-footer-links">
								<li className="tt-footer-link">
									<Link to="/">Home</Link>
								</li>
								<li className="tt-footer-link">
									<Link to="/dashboard">Dashboard</Link>
								</li>
								<li className="tt-footer-link mb-3">
									<Link to="/login">Login</Link>
								</li>
								<li className="tt-footer-link">
									<Link to="/privacy-policy">
										Privacy Policy
									</Link>
								</li>
								<li className="tt-footer-link">
									<Link to="/cookie-policy">
										Cookie Policy
									</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</footer>
		</>
	);
};

export default Layout;

interface NavigationProps {
	text: string;
	to: string;
}
