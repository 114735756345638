import { ApiError, Departure } from "../../types";
import { DepartureOperator } from "../../types/Departures";

export interface DeparturesState {
	originCrs: string | null;
	destinationCrs: string | null;
	originName: string | null;
	items: Departure[];
	operators: DepartureOperator[];
	messages: string[];
	loading: boolean;
	error: ApiError | null;
	fromDate: string | null;
	nextToken: string | null;
}

export const initialState: DeparturesState = {
	originCrs: null,
	destinationCrs: null,
	originName: null,
	items: [],
	operators: [],
	messages: [],
	loading: false,
	error: null,
	fromDate: null,
	nextToken: null,
};
