import { useEffect } from "react";
import useAppDispatch from "./useAppDispatch";
import useAppSelector from "./useAppSelector";
import { fetchRecentSearches } from "../store/searches/effects";

const useRecentSearches = () => {
	const {
		loading,
		error,
		items: searches,
	} = useAppSelector((x) => x.searches);
	const dispatch = useAppDispatch();

	const loadDepartures = () => {
		if (loading) {
			return;
		}

		dispatch(fetchRecentSearches());
	};

	useEffect(loadDepartures, [dispatch]);

	return {
		loading,
		error,
		searches,
	};
};

export default useRecentSearches;
