import { ApiError, RecentSearch } from "../../types";

export interface SearchesState {
	loading: boolean;
	error: ApiError | null;
	items: RecentSearch[];
}

export const initialState: SearchesState = {
	loading: false,
	error: null,
	items: [],
};
