import { FunctionComponent } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { Option } from "react-bootstrap-typeahead/types/types";

export interface SelectOperatorViewProps {
	options: StationOperatorOption[];
	onChange: (selected: Option[]) => void;
}

export interface StationOperatorOption {
	id: string; // operator code
	label: string;
	selected: boolean;
}

const SelectOperatorView: FunctionComponent<SelectOperatorViewProps> = ({
	options,
	onChange,
}) => (
	<Typeahead
		id="operator"
		filterBy={() => true}
		placeholder="All operators"
		onChange={onChange}
		options={options}
	/>
);

export default SelectOperatorView;
