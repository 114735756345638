import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./state";
import { fetchService } from "./effects";
import { ApiError } from "../../types";

const servicesSlice = createSlice({
	name: "services",
	initialState: initialState,
	reducers: {},
	extraReducers(builder) {
		builder.addCase(fetchService.pending, (state, action) => {
			state.loading = true;
		});
		builder.addCase(fetchService.fulfilled, (state, action) => {
			const service = action.payload!;
			state.items = state.items
				.filter((x) => x.id !== service.id)
				.concat(service);
			state.loading = false;
			state.error = null;
		});
		builder.addCase(fetchService.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload as ApiError;
		});
	},
});

export default servicesSlice.reducer;
