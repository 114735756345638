import { FunctionComponent } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { stopTrackingTrain, trackTrain } from "../../store/tracking/effects";

export interface TrackButtonProps {
	serviceId: string;
	origin: string;
	destination: string;
}

const TrackButton: FunctionComponent<TrackButtonProps> = ({
	serviceId,
	origin,
	destination,
}) => {
	const { loading } = useAppSelector((x) => x.tracking);
	const departures = useAppSelector((x) => x.departures.items);
	const trackedTrains = useAppSelector((x) => x.trackedTrains.items);
	const isTracked =
		(departures.find((x) => x.id === serviceId)?.tracked ?? false) ||
		!!trackedTrains.find((x) => x.trainId === serviceId);
	const dispatch = useAppDispatch();

	const handleClick = () => {
		if (loading) {
			return;
		}

		if (isTracked) {
			dispatch(stopTrackingTrain({ serviceId }));
		} else {
			dispatch(
				trackTrain({
					originCrs: origin,
					destinationCrs: destination,
					serviceId,
				})
			);
		}
	};

	return (
		<button type="button" className="btn btn-primary" onClick={handleClick}>
			{loading ? (
				<div className="spinner-border text-light" role="status">
					<span className="visually-hidden">Loading...</span>
				</div>
			) : isTracked ? (
				"Stop tracking"
			) : (
				"Track"
			)}
		</button>
	);
};

export default TrackButton;
