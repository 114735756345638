import { configureStore } from "@reduxjs/toolkit";
import departuresReducer from "./departures";
import trackingReducer from "./tracking";
import trackedTrainsReducer from "./trackedTrains";
import servicesReducer from "./services";
import searchesReducer from "./searches";
import createAccountReducer from "./createAccount";
import authReducer from "./auth";

export const store = configureStore({
	reducer: {
		departures: departuresReducer,
		tracking: trackingReducer,
		trackedTrains: trackedTrainsReducer,
		services: servicesReducer,
		searches: searchesReducer,
		createAccount: createAccountReducer,
		auth: authReducer,
	},
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
