import { FunctionComponent } from "react";
import { Departure } from "../../types";

import "./DepartureItem.scss";
import classNames from "classnames";
import ToolTip from "../ToolTip";

export interface DepartureProps {
	departure: Departure;
	onClick: (departure: Departure) => void;
}

const DepartureItem: FunctionComponent<DepartureProps> = ({
	departure,
	onClick,
}) => {
	const getDisplayTime = () => {
		const times = departure.departure;
		if (!times.scheduled && !times.estimated) {
			return "";
		}
		if (
			times.estimated !== "On time" &&
			times.estimated !== times.scheduled
		) {
			let estimated = "";
			if (times.estimated) {
				estimated = "(" + times.estimated + ")";
			}

			return (
				<span className="text-danger">
					{times.scheduled} {estimated}
				</span>
			);
		}
		return <span className="text-success">{times.scheduled ?? "-"}</span>;
	};

	const getPlatformToolTipText = () => {
		if (!departure.platform) {
			return "This train's platform is unknown.";
		}

		if (!departure.platformConfirmed) {
			return "This platform has not been confirmed.";
		}

		return "This platform has been confirmed.";
	};

	const platformClasses = classNames("tt-platform", {
		"text-muted": !departure.platformConfirmed,
	});

	return (
		<tr className="tt-service-item">
			<td onClick={() => onClick(departure)}>
				<div className="d-flex flex-row align-items-center justify-content-between">
					<div className="d-flex flex-row align-items-center">
						<span className="tt-destination">
							{departure.destination.name}
						</span>
						<span className="tt-operator text-muted">
							{departure.operator.name}
						</span>
					</div>
					{departure.tracked && (
						<div>
							<span className="tt-tracked text-info">
								Tracking
							</span>
						</div>
					)}
				</div>
				<div className="d-flex flow-row justify-content-between">
					{getDisplayTime()}
					<span className={platformClasses}>
						<ToolTip text={getPlatformToolTipText()}>
							{departure.platform || "-"}
						</ToolTip>
					</span>
				</div>
			</td>
		</tr>
	);
};

export default DepartureItem;
