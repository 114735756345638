import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { initialState } from "./state";
import { createAccount, sendOtp } from "./effects";
import { ApiError } from "../../types";
import { SetErrorAction } from "./actions";

const createAccountSlice = createSlice({
	name: "createAccount",
	initialState: initialState,
	reducers: {
		setError: (state, action: PayloadAction<SetErrorAction>) => {
			state.error = action.payload.error;
		},
		clearError: (state) => {
			state.error = null;
		},
	},
	extraReducers(builder) {
		builder.addCase(createAccount.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(createAccount.fulfilled, (state, action) => {
			const { subscriptionId, clientSecret } = action.payload!;
			state.loading = false;
			state.error = null;

			if (action.meta.arg.user.planType === "trial") {
				state.status = "done";
			} else {
				state.status = "payment";
				state.subscriptionId = subscriptionId;
				state.clientSecret = clientSecret;
			}
		});
		builder.addCase(createAccount.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload as ApiError;
		});

		builder.addCase(sendOtp.pending, (state) => {
			state.sendingOtpStatus = "requesting";
		});
		builder.addCase(sendOtp.fulfilled, (state) => {
			state.sendingOtpStatus = "requested";
			state.error = null;
		});
		builder.addCase(sendOtp.rejected, (state, action) => {
			state.sendingOtpStatus = "error";
			state.error = action.payload as ApiError;
		});
	},
});

export default createAccountSlice.reducer;
export const { setError, clearError } = createAccountSlice.actions;
