import { getAuthData } from "../context/auth";
import { ApiError } from "../types";
import { BASE_URL } from "../constants/api";
import UnauthorizedError from "./UnauthorizedError";

export const get = <T>(path: string) => {
	const authData = getAuthData();
	if (!authData) {
		throw new UnauthorizedError();
	}

	const url = BASE_URL + path;

	return fetch(url, {
		method: "GET",
		headers: {
			Authorization: "Bearer " + authData.accessToken,
		},
	})
		.then(async (res) => {
			switch (res.status) {
				case 200:
					return (await res.json()) as T;
				case 401:
					throw new UnauthorizedError();
				case 400:
				case 500:
					return (await res.json()) as ApiError;
				default:
					throw new Error("API returned status code: " + res.status);
			}
		})
		.catch((e) => {
			console.group(
				"An error occured while fetching data from the server"
			);
			console.log("URL", url);
			console.error(e);
			console.groupEnd();

			throw e;
		});
};

export const post = <TBody, TResponse = void>(
	path: string,
	body: TBody,
	anonymous: boolean = false
) => {
	const headers = new Headers();

	if (!anonymous) {
		const authData = getAuthData();
		if (!authData) {
			throw new UnauthorizedError();
		}

		headers.append("Authorization", "Bearer " + authData.accessToken);
	}

	const url = BASE_URL + path;

	return fetch(url, {
		method: "POST",
		headers: headers,
		body: JSON.stringify(body),
	})
		.then(async (res) => {
			switch (res.status) {
				case 200:
					return (await res.json()) as TResponse;
				case 201:
					return (await res.json()) as TResponse;
				case 204:
					return;
				case 401:
					throw new UnauthorizedError();
				case 400:
					return (await res.json()) as ApiError;
				case 423:
					return (await res.json()) as ApiError;
				case 500:
					return (await res.json()) as ApiError;
				default:
					throw new Error("API returned status code: " + res.status);
			}
		})
		.catch((e) => {
			console.group(
				"An error occured while posting data from the server"
			);
			console.log("URL", url);
			console.error(e);
			console.groupEnd();

			throw e;
		});
};

export const submitDelete = <TBody>(
	path: string,
	data: TBody | null = null
) => {
	const authData = getAuthData();
	if (!authData) {
		throw new UnauthorizedError();
	}

	const url = BASE_URL + path;
	let body = null;
	if (data) {
		body = JSON.stringify(data);
	}

	return fetch(url, {
		method: "DELETE",
		headers: {
			Authorization: "Bearer " + authData.accessToken,
		},
		body: body,
	})
		.then(async (res) => {
			switch (res.status) {
				case 200:
				case 204:
					return;
				case 401:
					throw new UnauthorizedError();
				case 400:
				case 500:
					return (await res.json()) as ApiError;
				default:
					throw new Error("API returned status code: " + res.status);
			}
		})
		.catch((e) => {
			console.group(
				"An error occured while posting data from the server"
			);
			console.log("URL", url);
			console.error(e);
			console.groupEnd();

			throw e;
		});
};
