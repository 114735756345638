import { createAsyncThunk } from "@reduxjs/toolkit";
import { register, requestOtp } from "../../api";
import { ApiError, CreateAccountResponse } from "../../types";
import { CreateAccountAction, SendOtpAction } from "./actions";
import { setAuthData } from "../../context/auth";
import { setIsLoggedIn } from "../auth";

export const createAccount = createAsyncThunk<
	CreateAccountResponse,
	CreateAccountAction
>("createAccount/register", async (action, thunkAPI) => {
	const response = await register(action.user);
	if ("error" in response!) {
		const error = response as ApiError;
		return thunkAPI.rejectWithValue(error);
	}
	const res = response as CreateAccountResponse;
	setAuthData(res);
	thunkAPI.dispatch(setIsLoggedIn());
	return res;
});

export const sendOtp = createAsyncThunk<void, SendOtpAction>(
	"createAccount/sendOtp",
	async (action, thunkAPI) => {
		const response = await requestOtp(action.phoneNumber);
		if (!response) {
			return;
		}
		if ("error" in response) {
			const error = response as ApiError;
			return thunkAPI.rejectWithValue(error);
		}
	}
);
