import { useEffect, useState } from "react";
import {
	AuthData,
	clearAuthStorage,
	getAuthData,
	setAuthData,
} from "../utils/auth";
import useAppSelector from "./useAppSelector";
import useAppDispatch from "./useAppDispatch";
import { setIsLoggedIn, setLoggedOut } from "../store/auth";

const useAuth = () => {
	const [auth, setAuth] = useState<AuthData | null>();
	const { isLoggedIn } = useAppSelector((x) => x.auth);
	const dispatch = useAppDispatch();

	useEffect(() => {
		setAuth(isLoggedIn ? getAuthData() : null);
	}, [isLoggedIn]);

	const handleLogout = () => {
		clearAuthStorage();
		dispatch(setLoggedOut());
	};

	const handleLogin = (data: AuthData) => {
		setAuth(data);
		setAuthData(data);
		dispatch(setIsLoggedIn());
	};

	return {
		ok: !!auth,
		accessToken: auth?.accessToken,
		logout: handleLogout,
		login: handleLogin,
	};
};

export default useAuth;
